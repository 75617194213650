import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  FormControl,
  InputBaseComponentsPropsOverrides,
  InputLabel,
  OutlinedInput,
  Box,
  useTheme,
  Skeleton,
  Alert,
  IconButton,
  Fade,
  debounce,
  Theme,
} from "@mui/material";
import {
  parseJwt,
  useCreateChatCompletionQuery,
  useGetOpenAITokenQuery,
  useLogChatChatCompletionArgsMutation,
} from "../../services/api";
import { createPrompt, createPromptProps } from "./AboutThisSegment/gptPrompt";
import { selectClaims } from "../../store/clientInventories";
import { store } from "../../store";
import { Check, ContentCopy, Email, EmailOutlined, Refresh } from "@mui/icons-material";
import SnackbarUtilsConfigurator from "../SnackbarUtilsConfigurator";
import { isDeepEqual } from "@mui/x-data-grid/internals";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { useNavigation } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import { v4 as uuid4 } from "uuid";

const sessionId = uuid4();

const BoxedPre = (props: {
  value: string;
  theme: Theme;
  skeleton: boolean;
  refetch: any;
}) => {
  const [showTick, setTick] = useState(false);
  const copyText = (value: string, doNotification: boolean) => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        if (doNotification)
          SnackbarUtilsConfigurator.success("Text copied to clipboard!");
        setTick(true);
        setTimeout(() => setTick(false), 3000);
      })
      .catch((e) => {
        console.log(e);
        SnackbarUtilsConfigurator.error("Copy failed, please try again.");
      });
  };
  return (
    <Box
      data-testid="GPTOutput"
      sx={{
        // p: 2,
        pl: 2,
        pr: 2,
        width: "100%",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden",
        display: "flex",
      }}
    >
      {props.skeleton ? (
        <Box
          sx={{
            flex: 1,
            pt: 2,
            ...props.theme.typography.body1,
          }}
        >
          <Skeleton width="100%" />
          <Skeleton width="100%" />
          <Skeleton width="60%" />
          <br />
          <Skeleton width="100%" />
          <Skeleton width="90%" />
          <Skeleton width="70%" />
          <br />
          <Skeleton width="100%" />
          <Skeleton width="30%" />
        </Box>
      ) : (
        <pre
          style={{
            whiteSpace: "pre-wrap",
            flex: 1,
            marginBottom: 0,
            ...props.theme.typography.body1,
          }}
        >
          {props.value}
        </pre>
      )}
      <Box
        sx={{
          width: "100%",
          textAlign: "right",
          pb: 2,
        }}
      >
        <IconButton
          color="primary"
          disabled={props.skeleton}
          onClick={props.refetch}
          title="Refresh GPT output"
        >
          <Refresh />
        </IconButton>
        <IconButton
          color="primary"
          disabled={props.skeleton || props.value.endsWith("|")}
          onClick={() => {
            // Here we do some clever subject detection, if the output starts
            // with 'Subject:' we take that subject and make it the subject of
            // an email

            let body = encodeURIComponent("\n\n" + props.value)
            let subject = ""
            if (props.value.startsWith("Subject: ")) {
              const splitValue = props.value.split('\n')
              subject = encodeURIComponent(splitValue[0].replace('Subject: ', ''))
              body = encodeURIComponent(splitValue.slice(2).join("\n"))
            }
            window.open(`mailto:?subject=${subject}&body=${body}`)
          }}
          title="Email"
        >
          <EmailOutlined />
        </IconButton>
        <IconButton
          color="primary"
          disabled={props.skeleton}
          onClick={() => {
            copyText(props.value, true);
          }}
          title="Copy to clipboard"
        >
          {showTick ? (
            <Fade in={showTick}>
              <Check color="success" />
            </Fade>
          ) : (
            <></>
          )}
          {!showTick ? (
            <Fade in={!showTick}>
              <ContentCopy />
            </Fade>
          ) : (
            <></>
          )}
        </IconButton>
      </Box>
    </Box>
  );
};

type GPTOutputProps = createPromptProps & { skeleton: boolean };

// Adapted from: https://medium.com/@gabrielmickey28/using-debounce-with-react-components-f988c28f52c1
const useDebounce = (
  value: GPTOutputProps,
  delay: number,
  setIsWaiting: any
): GPTOutputProps => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    // If the values are deeply equal, there's no need to debounce the setting
    // of the value, nothing should be changed at all!
    if (isDeepEqual(value, debouncedValue)) return;

    const timer = setTimeout(() => {
      setDebouncedValue(value);
      setTimeout(() => setIsWaiting(false), 50);
    }, delay);

    setIsWaiting(true);

    return () => {
      clearTimeout(timer);
      setIsWaiting(false);
    };
  }, [value, delay]);

  return debouncedValue;
};

const GPTOutput = (props: GPTOutputProps) => {
  const theme = useTheme();

  const claims = selectClaims(store.getState());
  const company = claims?.gptCompanyPrompt ?? "an ad funded media company";

  const gptCompanyPrompt = claims?.gptCompanyPrompt

  let dataSourceString = ""

  if (gptCompanyPrompt?.includes("Octave Audio") || gptCompanyPrompt?.includes("Sky Media") || gptCompanyPrompt?.includes("Acme Corporation")) {
    dataSourceString = "UK"
  }

  if (gptCompanyPrompt?.includes("the BBC selling")) {
    dataSourceString = "USA"
  }

  if (gptCompanyPrompt?.includes("Bauer Ireland")) {
    dataSourceString = "Ireland"
  }

  const dataPoints = dataSourceString !== "USA" ? "CACI Acorn data" : "Experian data"


  // isWaiting is used to determine whether the function is waiting to debounce or not
  const [isWaiting, setIsWaiting] = useState(false);
  const debouncedProps = useDebounce(props, 500, setIsWaiting);

  const {
    data: tokenData,
    isFetching: tokenIsFetching,
    error: tokenError,
    refetch: tokenRefetch,
  } = useGetOpenAITokenQuery();

  // Time for some error checking.
  const haveInvalidInput =
    props.writeAs === "" ||
    props.withATone === "" ||
    props.whileEmphasising.some((x) => x === "");

  const args = {
    model: "gpt-3.5-turbo",
    stream: true,
    messages: [
      {
        role: "system",
        content: `You work for ${company} in the country of ${dataSourceString} and you want to explain to advertisers and advertising agencies what types of audience segments you can offer them.

      Segments are created by Covatic and often inferred from Socio Economic data which divides the ${dataSourceString} population's ${dataPoints} into sociodemographic groups based on home location.
      
      You do not work for Covatic.`,
      },
      {
        role: "user",
        content: createPrompt({
          reachPerX: debouncedProps.reachPerX,
          Xweeks: debouncedProps.Xweeks,
          segmentName: debouncedProps.segmentName,
          segmentPrettyNames: debouncedProps.segmentPrettyNames,
          whileEmphasising: debouncedProps.whileEmphasising,
          withATone: debouncedProps.withATone,
          writeAs: debouncedProps.writeAs,
        }),
      },
    ],
  };

  // server-sent events from https://blog.logrocket.com/using-fetch-event-source-server-sent-events-react/
  const [data, setData] = useState("");

  // In order to refresh the data, this ID should be added to
  const [refreshId, setRefreshId] = useState(0);

  // const [checkDataBit, setCheckDataBit] = useState(false)

  // useEffect(() => {
  //   if (checkDataBit) {
  //     setTimeout(() => setCheckDataBit(false), 1000)
  //     return
  //   }

  //   if (data)
  // })
  const [outputError, setOutputError] = useState(false);
  const [
    sendLog,
    { isLoading: sendLogIsLoading },
  ] = useLogChatChatCompletionArgsMutation();
  const email = useAppSelector(
    (state) =>
      (state.userStatus.idToken !== null
        ? parseJwt(state.userStatus.idToken)?.email
        : undefined) ?? "unknown"
  );

  useEffect(() => {
    const ctrl = new AbortController();
    const fetchData = async (theargs: any) => {
      if (!tokenData) {
        return;
      }
      // setTimeout(() => {
      //   if (data.length === 0) {
      //     setRefreshId(refreshId + 10);
      //   }
      // }, 1000);
      await fetchEventSource(`https://api.openai.com/v1/chat/completions`, {
        method: "POST",
        signal: ctrl.signal,
        openWhenHidden: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokenData}`,
        },
        body: JSON.stringify(theargs),
        async onopen(res) {
          // If the response was not OK (e.g. a non-200) then set the output as having an error
          setOutputError(!res.ok);
        },

        onmessage(event) {
          if (event.data === "[DONE]") {
            setData((data) => data.slice(0, data.length - 1));
            ctrl.abort();
            sendLog({
              email,
              url: window.location.href,
              gpt_body: {
                props: debouncedProps,
                gpt_input: theargs,
                refreshId,
                sessionId,
                gpt_output: data,
              },
            });
          }
          const parsedData = JSON.parse(event.data);
          setData(
            (data) =>
              data.slice(0, (data.length || 1) - 1) +
              ((parsedData as any).choices?.[0]?.delta?.content ?? "") +
              "|"
          );
        },
        onclose() {
          // console.log("Connection closed by the server");
        },
        onerror(err) {
          setOutputError(true);
          // console.log("There was an error from server", err);
        },
      });
    };

    fetchData(args);

    return () => {
      ctrl.abort();
      setData("");
    };
  }, [JSON.stringify(args), refreshId, tokenData]);

  useEffect(() => {
    if (!email) {
      return;
    }
    sendLog({
      email,
      url: window.location.href,
      gpt_body: { props: debouncedProps, gpt_input: args, refreshId, sessionId },
    });
  }, [JSON.stringify(args), refreshId, tokenData, email]);

  // useEffect(() => {
  // }, [fetching])

  /// ALL HOOKS SHOULD BE BEFORE THIS LINE ///

  if (!!outputError || tokenError) {
    return (
      <Alert severity="error">
        Hmm, something went wrong. Please try saving your work and refreshing
        the page.
      </Alert>
    );
  }

  return (
    <>
      <FormControl sx={{ width: "100%", height: "100%" }}>
        <InputLabel shrink={true} htmlFor="gptoutput">
          GPT output
        </InputLabel>
        <OutlinedInput
          sx={{
            height: "100%",
          }}
          id="gptoutput"
          label="GPT output"
          notched
          slots={{ input: BoxedPre }}
          slotProps={{
            input: ({
              value: haveInvalidInput
                ? "Waiting for input below..."
                : // : output?.choices?.[0].message?.content,
                  data,
              theme,
              skeleton:
                data.length === 0 || isWaiting || debouncedProps.skeleton,
              refetch: () => setRefreshId(refreshId + 1),
            } as unknown) as InputHTMLAttributes<HTMLInputElement> &
              InputBaseComponentsPropsOverrides,
          }}
        />
      </FormControl>
    </>
  );
};

export default GPTOutput;
