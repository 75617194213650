// import logo from './logo.svg';
import React from "react";
import './App.css';
import { store } from './store';
import { Provider } from 'react-redux';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { ThemeProvider, createTheme } from '@mui/material/styles';

import Root from "./routes/root";
import ErrorPage from "./routes/ErrorPage";
import Segment from "./routes/segment";
import RouteList from "./routes/list";
import { SnackbarUtilsConfigurator } from "./components/SnackbarUtilsConfigurator";
import LoginProvider from "./components/LoginProvider";
import { SnackbarProvider } from "notistack";
import { api } from "./services/api";
import { logout, setIdToken, setLoggedIn } from "./store/userStatusSlice";
import { selectIsIdTokenExpired } from "./store/selectors";
import { useAppSelector } from "./store/hooks";
import { QueryStatus } from "@reduxjs/toolkit/dist/query";
import { Box, LinearProgress, Paper, Typography } from "@mui/material";
import ResponsiveAppBar from "./components/ResponsiveAppBar";
import LoadingBar from "./components/LoadingBar";

const theme = createTheme({
  palette: {
    primary: {
      main: "#02092E"
    }
  },
  typography: {
    overline: {
      fontWeight: 500,
      fontSize: 20,
      fontVariant: 'all-small-caps',
      letterSpacing: 0.15,
      lineHeight: "120%"
    }
  }
})


const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <RouteList />
      },
      {
        path: "segment",
        element: <Segment />
      },
      {
        path: "segment-advanced",
        element: <Segment />
      },
      {
        path: "segment/:metadataName",
        element: <Segment />
      },
    ]
  },
]);

function App() {


  // if (selectIsIdTokenExpired(store.getState())) {
  //   store.dispatch(logout())
  // }

  // if (AUTH_TOKEN === null || isBefore(EXPIRY_TIME, new Date())) {
  //   localStorage.removeItem("id_token")
  //   store.dispatch(logout())
  // }
  return (
    <React.StrictMode>
      <SnackbarProvider maxSnack={4}>
        <SnackbarUtilsConfigurator />
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <LoginProvider>
              <div className="App">
                <LoadingBar />
                <Box
                  sx={{
                    minHeight: "100vh",
                    display: "flex",
                    m: 0,
                    p: 0,
                    flexDirection: "column",
                  }}
                >
                  <RouterProvider router={router} />

                  <Paper
                    sx={{
                      position: "relative",
                      // bottom: 0,
                      // left: 0,
                      width: "100%",
                      backgroundColor: "primary.main",
                      color: "white",
                      pt: 0.5,
                      pb: 0.5,
                      m: 0,
                    }}
                    square={true}
                    variant="elevation"
                  >
                    <Typography variant="caption">
                      © Covatic - {new Date().getFullYear()}
                    </Typography>
                  </Paper>
                </Box>


              </div>
            </LoginProvider>
          </ThemeProvider>
        </Provider>
      </SnackbarProvider>
    </React.StrictMode>
  );
}

export default App;
