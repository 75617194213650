import * as React from "react";
import { useMemo } from "react";
import Box from "@mui/material/Box";
import MaterialReactTable from "material-react-table";
import type { MRT_ColumnDef } from 'material-react-table'; // If using TypeScript (optional, but recommended)
import Profile from "../services/profile";
import { useGetProfilesQuery, useGetSegmentsQuery, useReleaseProfileMutation, useUnreleaseProfileMutation } from "../services/api";
import { Button } from "@mui/material";
import { selectSegmentDisplayNames } from "../store/selectors";
import { store } from "../store";
import { formatSegmentName } from "../services/formatters";
import { useNavigate } from "react-router-dom";
import { Add } from "@mui/icons-material";
import SnackbarUtilsConfigurator from "./SnackbarUtilsConfigurator";
import { parseISO, formatRelative } from "date-fns"
import { enGB } from "date-fns/locale";
import LiveIcon from "./LiveIcon";
import DeleteDialog from "./List/DeleteDialog";
import { TableRow } from "./List/Types";


const numberFormatting: Partial<MRT_ColumnDef<TableRow>> = {
  muiTableHeadCellProps: {
    align: 'right',
  },
  muiTableBodyCellProps: {
    align: 'right',
  },
  maxSize: 40,
  size: 40,
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 1,
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};


export default function SegmentTable() {
  const navigate = useNavigate();

  // const [
  //   createProfile,
  //   { isLoading: isLoadingProfileMutation },
  // ] = useCreateProfileMutation();

  const [
    releaseProfile,
  ] = useReleaseProfileMutation();
  const [
    unreleaseProfile,
  ] = useUnreleaseProfileMutation();
  const { data, error, isFetching } = useGetProfilesQuery()


  const { data: allSegments } = useGetSegmentsQuery();
  const { data: allProfiles } = useGetProfilesQuery();

  const segmentDisplayNameMap = useMemo(
    () => selectSegmentDisplayNames(store.getState()),
    [allSegments, allProfiles]
  );
  
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false)
  const [segmentsToDelete, setSegmentsToDelete] = React.useState([] as string[])

  // There's a pretty brutal performance cost to rerendering the rows, so here
  // we make the data nice so that it doesn't have to be rendered every time


  const tableData: TableRow[] = useMemo(() => data?.filter(x => x.metadata.annotations.ad_campaign_estimator?.sync_id && !x.metadata.flags.deleted).map(
    (x: Profile): TableRow => ({
      code: x.metadata.name ?? "",
      released: x.metadata.flags.released,
      name: x.metadata.annotations["insights/display-name"] ?? "",
      description: x.metadata.annotations["insights/description"] ?? "",
      segments: x.metadata.annotations?.ad_campaign_estimator?.traits_included?.map(s => formatSegmentName(segmentDisplayNameMap, s)).join("; ") ?? "",
      createdBy: x.metadata.annotations.ad_campaign_estimator?.created_by ?? "Unknown",
      duration: (x.metadata.annotations.ad_campaign_estimator?.estimated_duration ?? "(missing)") + " weeks",
      estimatedAvails: x.metadata.annotations.ad_campaign_estimator?.estimated_avails?.toLocaleString() ?? "(missing)",
      estimatedReach: x.metadata.annotations.ad_campaign_estimator?.estimated_reach?.toLocaleString() ?? "(missing)",
      lastUpdated: parseISO(x.metadata.annotations["insights/last-updated"] ?? ""),
    })).sort((a, b) => +b.lastUpdated - +a.lastUpdated) ?? [], [allSegments, allProfiles, data])

  // indexedProfiles are profiles indexed by metadata.name (or 'code' in business parlance)
  // const indexedProfiles: { [_id: string]: Profile } = useMemo(() => Object.fromEntries(
  //   data?.map(profile => [profile.metadata.name ?? "", profile]) ?? []
  // ),
  //   [data])

  const columns: MRT_ColumnDef<TableRow>[] = useMemo(() => [
    // { field: "id", headerName: "ID", width: 90 },
    {
      accessorKey: "released",
      header: "",
      muiTableBodyCellProps: {
        align: "center"
      },
      maxSize: 10,
      size: 10,
      Cell: ({ cell }) => (
        cell.getValue<boolean>() ? <LiveIcon /> : <></>
      ),
    },
    {
      accessorKey: "code",
      header: "Code",
      maxSize: 10,
      size: 10,
    },
    {
      accessorKey: "name",
      header: "Name"
    },
    {
      accessorKey: "description",
      header: "Description",
      minSize: 250,
    },
    // We don't need this right now, so I've removed it
    // {
    //   header: "Segments",
    //   // We make the outcome a string to make it searchable, and then convert it back when we render
    //   accessorKey: "segments",
    //   size: 400,

    // },
    {
      accessorKey: "duration",
      // accessorFn: row => (row as Profile).metadata.annotations.ad_campaign_estimator?.estimated_duration + " weeks",
      header: "Duration",
      ...numberFormatting,
    },
    {
      // accessorFn: row => (row as Profile).metadata.annotations.ad_campaign_estimator?.estimated_avails?.toLocaleString() ?? "unknown",
      accessorKey: "estimatedAvails",
      header: "Estimated inventory",
      ...numberFormatting,
    },
    {
      // accessorFn: row => (row as Profile).metadata.annotations.ad_campaign_estimator?.estimated_reach?.toLocaleString() ?? "unknown",
      accessorKey: "estimatedReach",
      header: "Estimated reach",
      ...numberFormatting,
    },
    {
      accessorKey: "lastUpdated",
      header: "Last Updated",
      Cell: ({ cell }) => formatRelative(cell.getValue<Date>(), new Date(), {
        locale: enGB
      }),
      ...numberFormatting,
    },
    {
      accessorKey: "createdBy",
      // accessorFn: row => (row as Profile).metadata.annotations.ad_campaign_estimator?.created_by ?? "Unknown",
      header: "Last updated by",
      size: 50
    },
  ], [tableData]);



  return (
    <Box>
      <MaterialReactTable

        // memoMode="cells"
        columns={columns}
        // data={data?.filter(x => x.metadata.annotations.ad_campaign_estimator?.sync_id) ?? []}
        data={tableData}
        enableColumnActions={false}
        enableColumnFilters={false}
        enableHiding={false}
        enableFullScreenToggle={false}
        enableDensityToggle={false}

        muiTableBodyRowProps={({ row }) => ({
          onClick: () => {
            navigate(`/segment/${row.getValue('code')}`)
          },
          sx: {
            cursor: "pointer"
          }
        })}

        displayColumnDefOptions={{
          "mrt-row-select": {
            muiTableBodyCellProps: {
              padding: 'none',
              size: "small",
              align: "right",
              sx: {
                p: -4,
                m: -4,
                minWidth: "max(1px, 1px)",
              }
            },
            muiTableHeadCellProps: {
              padding: 'none',
              size: "small",
              align: "right",
              sx: {
                p: -4,
                m: -4,
                maxWidth: "1px",
                minWidth: "max(1px, 1px)",
              }
            }
          }
        }}


        enableRowSelection
        positionToolbarAlertBanner="none"
        renderTopToolbarCustomActions={({ table }) => (
          <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
            <Button variant="contained" startIcon={<Add />} onClick={() => navigate('/segment')}>
              New
            </Button>
            <Button
              color="primary"
              disabled={!table.getSelectedRowModel().rows.filter(row => !row.getValue('released')).length}
              onClick={async () => {
                const rows = table.getSelectedRowModel().rows
                const segmentNamesToRelease = rows.map(row => row.getValue('code') as string)
                Promise.all(
                  segmentNamesToRelease.map(name => releaseProfile(name))
                ).then(d => {
                  console.log(d)
                  if (d.some(x => "error" in x)) {
                    SnackbarUtilsConfigurator.warning(`Sorry, some segments were not published, please retry`)
                    return
                  }
                  table.setRowSelection(prev => ({}))
                  SnackbarUtilsConfigurator.success(`Segments ${segmentNamesToRelease.join(", ")} were published`)
                })
              }}
              variant="contained"
            >
              Publish ({table.getSelectedRowModel().rows.filter(row => !row.getValue('released')).length})
            </Button>
            <Button
              disabled={!table.getSelectedRowModel().rows.filter(row => row.getValue('released')).length}
              onClick={async () => {
                const rows = table.getSelectedRowModel().rows
                const segmentNamesToRelease = rows.map(row => row.getValue('code') as string)
                Promise.all(
                  segmentNamesToRelease.map(name => unreleaseProfile(name))
                ).then(d => {
                  console.log(d)
                  if (d.some(x => "error" in x)) {
                    SnackbarUtilsConfigurator.warning(`Sorry, some segments remain published, please retry`)
                    return
                  }
                  table.setRowSelection(prev => ({}))
                  SnackbarUtilsConfigurator.success(`Segments ${segmentNamesToRelease.join(", ")} were unpublished`)
                })
              }}
              variant="contained"
            >
              Unpublish ({table.getSelectedRowModel().rows.filter(row => row.getValue('released')).length})
            </Button>
            <Button
              color="error"
              disabled={!table.getSelectedRowModel().rows.length}
              onClick={async () => {
                const rows = table.getSelectedRowModel().rows
                setSegmentsToDelete(rows.map(row => row.getValue('code')))
                setDeleteModalOpen(true)
              }}
              variant="contained"
            >
              Delete ({table.getSelectedRowModel().rows.length})
            </Button>

            <DeleteDialog
            open={deleteModalOpen}
            onClose={() => {
              setDeleteModalOpen(false)
              table.toggleAllRowsSelected(false)
            }}
            segments={segmentsToDelete.map(code => ({code: code, name: segmentDisplayNameMap["REL" + code]}))}
            />
            
            {/* This feature will likely be used at a later date, so I've
            chosen not to delete it (yet!) */}
            {/* <KebabMenu table={table}/> */}
            
          </Box>
        )}

        muiToolbarAlertBannerProps={
          (error && !isFetching) ? {
            color: 'error',
            children: "Hmm, there's been an error on our end. Please try refreshing the page."
          } : undefined
        }

        // filterFns={{
        //   customFilterFunc: (row: any, id, filterValue) => {
        //     console.log("Filtering by id and row")
        //     console.log(id)
        //     console.log(row.getValue(id))
        //     // console.log(row.getValue(id))
        //     // return row.getValue(id).contains(filterValue)
        //     return true
        //   }
        // }}
        // globalFilterFn="customFilterFunc"
        // globalFilterModeOptions={{
        //   columns: ['']
        // }}

        // enableColumnResizing
        // columnResizeMode="onChange"
        initialState={{
          density: 'compact',
          showGlobalFilter: true
        }}
        state={{
          isLoading: isFetching,
          showAlertBanner: (error && !isFetching)
        }}
        muiTablePaperProps={{
          elevation: 0
        }}
        muiTableBodyCellProps={{
          sx: {
            whiteSpace: "normal"
          }
        }}
      // onRowSelectionChange={setRowSelection} //hoist internal state to your own state (optional)
      // state={{ rowSelection }} //manage your own state, pass it back to the table (optional)
      // tableInstanceRef={tableInstanceRef} //get a reference to the underlying table instance (optional)
      />
    </Box>
  );
}
