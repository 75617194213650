import { Alert, Box } from "@mui/material";
import { formatSegmentName } from "../../services/formatters";
import React, { useMemo, useRef } from "react";
import {
  Segment,
  useGetProfilesQuery,
  useGetReachAndAvailsQuery,
  useGetSegmentsQuery,
} from "../../services/api";
import { store } from "../../store";
import { selectSegmentDisplayNames } from "../../store/selectors";
import GPTOutput from "./GPTOutput";
import { Form, FormSpy } from "react-final-form";
import RadioGroupWithOther from "./AboutThisSegment/RadioGroupWithOther";
import { ls } from "./AboutThisSegment/ls";
import CheckboxGroupWithOther from "./AboutThisSegment/CheckboxGroupWithOther";

////// Write ///////
const writeAsPrompts = {
  "a paragraph": "a single paragraph",
  "a few paragraphs": "a few paragraphs",
  email: "an email (starting with subject)",
  "bullet points": "bullet points",
} as const;

function isValidWriteAsPrompt(k: string): k is keyof typeof writeAsPrompts {
  return k in writeAsPrompts;
}

///// Tone ///////
const withATonePrompts = Object.fromEntries(
  ["neutral", "persuasive", "formal", "friendly"].map((x) => [x, x])
);

////// Emphasise ///////
const whileEmphasisingPrompts = [
  "potential clients",
  "privacy",
  "reach",
  "household income",
] as const;

interface AboutThisSegmentProps {
  // OK, we need all the data to calculate reach
  segmentName: string;
  estimatedWeeks: number;
  segments: Segment[];
  operator: "AND" | "OR";

  // Eventually this component should probably output this to somewhere and
  // save it in the database. But that doesn't need to happen right now
}

interface FormProps {
  write: string;
  writeOther: string;
  tone: string;
  toneOther: string;
  emphasise: string[];
  emphasiseOther: string;
}

const AboutThisSegment = (props: AboutThisSegmentProps) => {
  // RKT Query stuff
  const {
    data: reachAndAvails,
    isFetching: reachAndAvailsFetching,
  } = useGetReachAndAvailsQuery({
    profiles: props.segments.map((s) => s.segment),
    operator: props.operator,
  });
  const estimatedReach = useMemo(
    () => reachAndAvails?.[props.estimatedWeeks - 1]?.reach ?? 0,
    [reachAndAvails, props.estimatedWeeks]
  );

  const {
    data: allSegments,
    isFetching: allSegmentsFetching,
    error: allSegmentsError,
  } = useGetSegmentsQuery();

  const { data: allCovaticSegments } = useGetProfilesQuery();

  const segmentDisplayNameMap = React.useMemo<{ [k: string]: string }>(() => {
    return selectSegmentDisplayNames(store.getState());
  }, [allSegments, allCovaticSegments]);

  const gptOutputShouldBeGenerated = props.segments.length > 0;

  const writeOtherInput = useRef<HTMLInputElement>(null);
  const toneOtherInput = useRef<HTMLInputElement>(null);
  const emphasiseOtherInput = useRef<HTMLInputElement>(null);

  const initialValues = {
    // Previous versions of this tool used 'writeAsPrompt' for localStorage, this version uses 'write', same for the rest of these initial values
    write:
      ls.get<string>("writeAsPrompt") ??
      ls.get<string>("write") ??
      "a paragraph",
    writeOther:
      ls.get<string>("writeAsOtherPrompt") ??
      ls.get<string>("writeOther") ??
      "",
    tone:
      ls.get<string>("withATonePrompt") ?? ls.get<string>("tone") ?? "neutral",
    toneOther:
      ls.get<string>("withAToneOtherPrompt") ??
      ls.get<string>("toneOther") ??
      "",
    emphasise:
      ls.getArray<string[]>("whileEmphasisingPrompt") ??
      ls.getArray<string[]>("emphasise") ??
      [],
    emphasiseOther: ls.get<string>("emphasiseOther") ?? "",
  };

  // Once the 'migration' of using the old values in initialValues is complete,
  // remove the old ones as needed
  ls.remove("writeAsPrompt");
  ls.remove("writeAsOtherPrompt");
  ls.remove("withATonePrompt");
  ls.remove("withAToneOtherPrompt");
  ls.remove("whileEmphasisingPrompt");

  return (
    // We only need this form for validation, it never 'submits' anything, just
    // changes the inputs to GPTOutput
    <Form
      onSubmit={(values: FormProps) => {}}
      initialValues={initialValues}
      render={() => (
        <Box
          sx={{
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              pt: 3,
              pb: 3,
              pl: 1,
              pr: 1,
              flexGrow: 1,
            }}
          >
            <FormSpy
              render={({ values: valuesAsAny, errors }) => {
                const values = valuesAsAny as FormProps;
                const invalidOptions = !values.tone || !values.write;
                return gptOutputShouldBeGenerated ? (
                  invalidOptions ? (
                    <Alert severity="info">
                      Continue by filling out the 'other' box below
                    </Alert>
                  ) : (
                    <GPTOutput
                      skeleton={reachAndAvailsFetching || allSegmentsFetching}
                      reachPerX={estimatedReach}
                      Xweeks={props.estimatedWeeks}
                      segmentName={props.segmentName}
                      segmentPrettyNames={props.segments.map((s) =>
                        formatSegmentName(segmentDisplayNameMap, s.segment)
                      )}
                      whileEmphasising={values.emphasise}
                      withATone={
                        withATonePrompts[(values as FormProps).tone] ??
                        values.toneOther
                      }
                      writeAs={
                        isValidWriteAsPrompt(values.write)
                          ? writeAsPrompts[values.write]
                          : values.writeOther
                      }
                    />
                  )
                ) : (
                  <Alert severity="info">
                    Try filling out some segments on the left to generate some
                    text from OpenAI's GPT.
                  </Alert>
                );
              }}
            />
          </Box>
          <Box
            sx={{
              p: 1,
            }}
          >
            <RadioGroupWithOther
              title="Write"
              placeholder="e.g. a text, a video script"
              prompts={writeAsPrompts}
              primaryField="write"
              otherField="writeOther"
              inputRef={writeOtherInput}
            />
          </Box>
          <Box
            sx={{
              p: 1,
            }}
          >
            <RadioGroupWithOther
              title="Tone"
              placeholder="e.g. serious, exciting"
              prompts={withATonePrompts}
              primaryField="tone"
              otherField="toneOther"
              inputRef={toneOtherInput}
            />
          </Box>
          <Box
            sx={{
              p: 1,
            }}
          >
            <CheckboxGroupWithOther
              title="Emphasise"
              placeholder="e.g. market fit"
              prompts={Object.fromEntries(
                whileEmphasisingPrompts.map((x) => [x, x])
              )}
              primaryField="emphasise"
              otherField="emphasiseOther"
              inputRef={emphasiseOtherInput}
            />
          </Box>
        </Box>
      )}
    />
  );
};

export default AboutThisSegment;
