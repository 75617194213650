import { Segment } from "../../services/api";

export const operatorsText = Object.freeze({
  AND: "Include users who are likely to match ALL of the above",
  OR: "Include users who are likely to match ANY of the above",
});

export interface FormDefaults {
  name: string;
  description: string;
  targetduration: string;
  selectedsegments: Segment[];
  profileVersion: number;
  metadataName?: string;
  operator: keyof typeof operatorsText;
  released: boolean;
  deleted: boolean;

  // These fields are only for advanced usage. They can be pre-filled by
  // assigned profiles or using the /segment-advanced (for use only by Covatic
  // employees)
  query?: string;
  external: boolean;
  // cvcqlVersion only 1.2.0 and 1.5.0 is supported by the API at this time
  cvcqlVersion: "1.2.0" | "1.5.0" | "1.8.0";
  shouldIncludeAdCampaignEstimatorMetadata: boolean;
  slProfile?: boolean;
  isInMoment?: boolean;
  isAssignedProfile?: boolean;
}
