import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { RefObject, useEffect } from "react";
import { Field, FormSpy } from "react-final-form";
import { ls } from "./ls";

interface RadioGroupWithOtherProps {
  title: string;
  prompts: { [title: string]: string };
  placeholder: string;

  primaryField: string;
  otherField: string;

  inputRef: RefObject<HTMLInputElement>;
}

const RadioGroupWithOther = (props: RadioGroupWithOtherProps) => {
  return (
    <Field name={props.primaryField}>
      {({ input, meta }) => {
        return (
          <FormControl>
            <FormLabel>{props.title}:</FormLabel>
            <RadioGroup
              row
              {...input}
              onChange={(e) => {
                input.onChange(e);
                ls.set<string>(props.primaryField, e.target.value);
              }}
              onFocus={input.onFocus}
              onBlur={input.onBlur}
              name={input.name}
            >
              {Object.keys(props.prompts).map((prompt) => (
                <FormControlLabel
                  value={prompt}
                  control={<Radio />}
                  label={prompt}
                />
              ))}
              <Field
                name={props.otherField}
                validate={(x) =>
                  typeof x === "string" && x.length > 0
                    ? undefined
                    : "invalid input"
                }
              >
                {({ input: otherInput, meta: otherMeta }) => {
                  // This boolean is complicated so it deserves explaining
                  const isInvalid: boolean =
                    // If there is an error (cast to a boolean)
                    !!otherMeta.error &&
                    // and the current selected value is this value
                    input.value === otherInput.value &&
                    // and the text field is *not* currently focused
                    !(document.activeElement === props.inputRef.current);
                  return (
                    <>
                      <FormControlLabel
                        value={otherInput.value}
                        onFocus={() => props.inputRef.current?.focus()}
                        control={<Radio />}
                        // onFocus={() => writeOtherTextField.current && writeOtherTextField.current.focus()}
                        label={
                          <TextField
                            placeholder={props.placeholder}
                            onChange={(e) => {
                              otherInput.onChange(e.target.value);
                              ls.set<string>(props.otherField, e.target.value);
                              input.onChange(e.target.value);
                              ls.set<string>(
                                props.primaryField,
                                e.target.value
                              );
                            }}
                            onFocus={(e) => {
                              if (e.type === "focus") {
                                input.onChange(otherInput.value as any);
                                ls.set<string>(
                                  props.primaryField,
                                  otherInput.value
                                );
                              }
                            }}
                            inputProps={{
                              ref: props.inputRef,
                            }}
                            value={otherInput.value}
                            error={isInvalid}
                            helperText={
                              isInvalid
                                ? "This field cannot be selected and empty"
                                : ""
                            }
                            label="other"
                          />
                        }
                      />
                    </>
                  );
                }}
              </Field>
            </RadioGroup>
          </FormControl>
        );
      }}
    </Field>
  );
};

export default RadioGroupWithOther;
