export const ls = {
  set: <T extends string>(key: string, value: T) =>
    localStorage.setItem(`AboutThisSegment: ${key}`, value),
  remove: (key: string) =>
    localStorage.removeItem(`AboutThisSegment: ${key}`),
  get: <T extends string>(key: string): T | null =>
    localStorage.getItem(`AboutThisSegment: ${key}`) as T | null,
  setArray: <T extends string[]>(key: string, value: T) =>
    localStorage.setItem(
      `AboutThisSegment_array: ${key}`,
      JSON.stringify(value)
    ),
  getArray: <T extends string[]>(key: string) => {
    const item = localStorage.getItem(`AboutThisSegment_array: ${key}`);
    if (item === null) return item;

    return JSON.parse(item) as T;
  },
} as const;
