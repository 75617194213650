import { AppBar, Paper, Select, Toolbar, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { Box } from "@mui/system";
import { QueryStatus } from "@reduxjs/toolkit/dist/query";
import React, { useRef, useState } from "react";

import { Outlet, Link } from "react-router-dom";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import { api } from "../services/api";
import { store } from "../store";
import { useAppSelector } from "../store/hooks";
import { setIdToken } from "../store/userStatusSlice";

export default function Root() {
  // const isLoading = useAppSelector((state) => {
  //   return [
  //     ...Object.values(state.api.mutations),
  //     ...Object.values(state.api.queries),
  //   ].some((query) => {
  //     return query && query.status === QueryStatus.pending;
  //   });
  // });

  const idTokenFromStore = useAppSelector((state) => state.userStatus.idToken);

  // const hashparams = new URLSearchParams(location.hash.substring(1));
  // // These are the only two places we store the ID token, so if neither of them
  // // have it then the idToken will be null
  // const idToken = hashparams.get("id_token") ?? localStorage.getItem("idToken")
  // if (idToken !== null && idToken !== idTokenFromStore) {
  //   // If idToken is set, set it in the store
  //   store.dispatch(setIdToken(idToken))

  //   // Reset the location hash to prevent it from persisting and neaten up the URL
  //   location.hash = '#'
  // }

  // const [initialFetchHasNotBeenDone, setInitialFetchHasNotBeenDone] = useState(true)

  // if (idTokenFromStore && initialFetchHasNotBeenDone) {
  //   store.dispatch(api.endpoints.getSegments.initiate())
  //   store.dispatch(api.endpoints.getProfiles.initiate())
  //   setInitialFetchHasNotBeenDone(false)
  // }
  return (
    <>
        <ResponsiveAppBar></ResponsiveAppBar>
        <Box sx={{ flexGrow: 2 }} id="detail">
          {idTokenFromStore ? <Outlet /> : <></>}
        </Box>
    </>
  );
}
