/**
 * This function formats segment names to be more human friendly
 * @param segmentSegmentToDisplayName map of segment identifier to display name
 * @param segment Segment identifier e.g. REL2029
 * @returns 
 */
export const formatSegmentName = (
  segmentSegmentToDisplayName: { [segment: string]: string },
  segment: string
): string => {
  let code = null
  if (segment.slice(0, 3) === "REL") {
    code = segment.slice(3)
  }
  // return segmentSegmentToDisplayName[segment] ?? segment + "(?)";
  return (segmentSegmentToDisplayName[segment] ?? segment + "(?)") + (code ? " (" + code + ")" : "")
};
