/* eslint-disable no-restricted-globals */
import { Box, Button, Modal, Typography } from "@mui/material";
import * as React from "react";
import { store } from "../store";
import { useAppSelector } from "../store/hooks";
import { howLongAgoDidTheIdTokenExpire, isIdTokenExpired, setIdToken } from "../store/userStatusSlice";
import SnackbarUtilsConfigurator from "./SnackbarUtilsConfigurator";

interface Props {
  children?: React.ReactNode;
  // any props that come into the component
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 1,
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const currentURL = `${window.location.protocol}//${window.location.host}`;
const devLoginURL = `https://dev-client-tooling.sign-in.covatic.io/login?client_id=59hhiv47amlgtamu06opqg6i9p&response_type=token&scope=email+openid&redirect_uri=${currentURL}`;
const octaveLoginURL = `https://octave.sign-in.covatic.io/login?client_id=1osk35j6p2oirjata1fk6j1ub6&response_type=token&scope=email+openid&redirect_uri=${currentURL}`;
const skyLoginURL = `https://sky.sign-in.covatic.io/login?client_id=3db132o475vd6o2020uj2cjfg1&response_type=token&scope=email+openid&redirect_uri=${currentURL}`;
const bbcLoginURL = `https://bbc.sign-in.covatic.io/login?client_id=73tt12iackv7jmuvhffqdnu9sl&response_type=token&scope=email+openid&redirect_uri=${currentURL}`;
const nbcuLoginURL = `https://nbcu.sign-in.covatic.io/login?client_id=63pjuagfr7qp5620r9v57pcst&response_type=token&scope=email+openid&redirect_uri=${currentURL}`;
const bauerIreLoginURL = `https://bauer-ireland.sign-in.covatic.io/login?client_id=4qm528ms8vjghmgo8e961q8pk5&response_type=token&scope=email+openid&redirect_uri=${currentURL}`;

const LoginProvider = ({ children }: Props) => {
  // const loggedIn = useAppSelector((state) => state.userStatus.loggedIn);
  // const loggedOut = selectIsTokenExpired(store.getState())
  const loggedOut = useAppSelector((state) =>
    isIdTokenExpired(state.userStatus.idToken)
  );
  const preventLogoutRedirect = useAppSelector(
    (state) => state.userStatus.preventLogoutRedirect
  );
  const navigate = (url: string) => (window.location.href = url);
  const loginURL = window.location.host.startsWith("octave")
    ? octaveLoginURL
    : window.location.host.startsWith("sky")
    ? skyLoginURL
    : window.location.host.startsWith("bbc")
    ? bbcLoginURL
    : window.location.host.startsWith("nbcu")
    ? nbcuLoginURL
    : window.location.host.startsWith("bauer")
    ? bauerIreLoginURL
    : devLoginURL;

  const idTokenFromStore = useAppSelector((state) => state.userStatus.idToken);

  const hashparams = new URLSearchParams(location.hash.substring(1));
  // These are the only two places we store the ID token, so if neither of them
  // have it then the idToken will be null
  const idToken = hashparams.get("id_token") ?? localStorage.getItem("idToken");

  console.log(howLongAgoDidTheIdTokenExpire(idToken))

  // If idToken is null at this point, there's no way to get it so it's time to
  // redirect to the login page (as long as the store has not indicated that
  // logout redirection should not be prevented)
  if ((idToken === null || (howLongAgoDidTheIdTokenExpire(idToken) ?? 0) > 10*60) && !preventLogoutRedirect) {
    navigate(loginURL);

    // We render a page because if we don't, LoginProvider shows a flash of the
    // logout dialog which feels strange, especially because the layout of the
    // login page is so different.
    //
    // Perhaps it should look something like the login page ... but there's no
    // time to consider stuff like that right now
    return <div>Redirecting to sign in...</div>;
  }


  if (idToken !== null && idToken !== idTokenFromStore) {
    // If idToken is set, set it in the store
    store.dispatch(setIdToken(idToken));

    // Reset the location hash to prevent it from persisting and neaten up the URL
    location.hash = "#";
  }

  return (
    <>
      {children}
      <Modal open={loggedOut}>
        <Box sx={style}>
          <Typography variant="h6" component="h2">
          Session expired
          </Typography>
          <Typography>Please log back in</Typography>
          {/* <Button variant="contained" startIcon={<Add />} sx={{ m: 1 }}> */}
          <Box sx={{ textAlign: "right" }}>
            <Button
              variant="contained"
              sx={{ m: 1 }}
              onClick={() =>
                loginURL !== undefined
                  ? navigate(loginURL)
                  : SnackbarUtilsConfigurator.error(
                      "Hmm, something went wrong with getting the login URL."
                    )
              }
            >
              login
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default LoginProvider;
