import React, { useEffect, useState } from "react";
import { Skeleton, TextField, TextFieldProps } from "@mui/material";

const CvcTextField = (props: TextFieldProps & { showSkeleton: boolean }) => {
  return props.showSkeleton ? (
    <Skeleton width="100%">
      <TextField
        InputLabelProps={{ shrink: true }}
        sx={{
          mt: 2,
          mb: 2,
          width: "100%",
        }}
        {...props}
      />
    </Skeleton>
  ) : (
    <TextField
      InputLabelProps={{ shrink: true }}
      sx={{
        mt: 2,
        mb: 2,
        width: "100%",
      }}
      {...props}
    />
  );
};

export default CvcTextField;
