import { MRT_ColumnDef } from "material-react-table";
import React, { useMemo } from "react";
import { Segment, useGetReachAndAvailsQuery } from "../../services/api";
import MaterialReactTable from "material-react-table";
import { Skeleton, useTheme } from "@mui/material";

interface reachAndAvailsRow {
  label: string;
  value: number;
}
const reachAndAvailsColumns: MRT_ColumnDef<reachAndAvailsRow>[] = [
  {
    accessorFn: (row) => row.label,
    header: " ",
    minSize: 3 * 12,
    size: 4 * 12,
  },
  {
    accessorFn: (row) => row.value.toLocaleString(),
    header: "Estimation",
    minSize: [..."Estimation"].length * 12,
    size: [..."Estimation"].length * 12,
    muiTableHeadCellProps: {
      align: "right",
    },
    muiTableBodyCellProps: {
      align: "right",
    },
  },
];

const EstimationTable = ({
  targetduration,
  selectedsegments,
  showSkeleton,
  operator
}: {
  targetduration: string;
  selectedsegments: Segment[];
  showSkeleton: boolean,
  operator: "AND" | "OR"
}) => {
  const { data, isFetching, status } = useGetReachAndAvailsQuery(
    {
      profiles: selectedsegments.map((s) => s.segment),
      operator
    }
  );

  const tableData = useMemo<reachAndAvailsRow[]>(
    () => [
            {
              label: "Reach",
              value: !data ? 0 : (data[+targetduration - 1]?.reach ?? 0),
            },
            {
              label: "Inventory",
              value: !data ? 0 : (data[+targetduration - 1]?.avails ?? 0),
            },
          ],
    [data, targetduration]
  );

  const theme = useTheme()


  return (
      <MaterialReactTable
        // memoMode="table-body"
        muiTablePaperProps={{
          elevation: 0,
          sx: {
            mt: 2,
            mb: 2,
            width: {
              xs: "99%",
              xl: "99.5%"
            },
            border: 1,
            borderColor: "rgba(0, 0, 0, 0.23)",
            borderStyle: "solid", 
            borderRadius: "4px",
            overflow: "hidden"
          },
        }}
        muiTableBodyRowProps={{
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0)"
          }
        }}
        muiTableHeadRowProps={{
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0)"
          }
        }}
        columns={reachAndAvailsColumns}
        data={tableData}
        enableColumnActions={false}
        enableColumnFilters={false}
        enableHiding={false}
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        enableFilters={false}
        enableTableFooter={false}
        enablePagination={false}
        enableTableHead={true}
        enableTopToolbar={false}
        enableBottomToolbar={false}
        state={{
          isLoading: isFetching,
          showSkeletons: isFetching || showSkeleton
        }}
      />
  );
};

export default EstimationTable;
