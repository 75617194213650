import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from '@reduxjs/toolkit/query/react'
import pizzaReducer from "./store/pizzaSlice";
import userStatusReducer from "./store/userStatusSlice";
import selectedSegmentsReducer from "./store/selectedSegmentsSlice";
import { api, rtkQueryErrorLogger } from "./services/api";


export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    pizza: pizzaReducer,
    userStatus: userStatusReducer,
    selectedSegments: selectedSegmentsReducer,
  },
  middleware: (getDefaultMiddleware) => {
    // return getDefaultMiddleware().concat([api.middleware, rtkQueryErrorLogger])
    return getDefaultMiddleware().concat([api.middleware, rtkQueryErrorLogger])
  },
});

setupListeners(store.dispatch)

// Taken from: https://redux.js.org/usage/usage-with-typescript
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch