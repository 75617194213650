import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface APIConfig {
  // This is more for my sanity, it's unlikely to be used anywhere in the UI
  displayName: string;

  baseUrl: string;
  clientId: string;
}

export const ClientInventories: Readonly<{
  [clientInventoryName: string]: APIConfig[];
}> = {
  // test is a client inventory only for cypress testing
  test: [
    {
      baseUrl: "https://client-tooling-zz-test-1.covatic.io",
      clientId: "2a75e66ec19a5a161a15c976",
      displayName: "Three body simulation",
    },
    {
      baseUrl: "https://client-tooling-yy-test-2.covatic.io",
      clientId: "a75e766ec19a161aa159ca",
      displayName: "Wallfacer messaging system",
    }
  ],
  mobiletestapp: [
    {
      baseUrl: "https://client-tooling-eu-central-1.covatic.io",
      clientId: "5c94c65a35e5ff00106898b3",
      displayName: "TestApp"
    }

  ],
  // development points to Frankfurt
  development: [
    {
      baseUrl: "https://client-tooling-eu-central-1.covatic.io",
      clientId: "62a75e66ec19a5a161a15c97",
      displayName: "News UK Mobile",
    },
    {
      baseUrl: "https://client-tooling-eu-central-1.covatic.io",
      clientId: "63247594f1861e200ef5b102",
      displayName: "News UK Browser",
    },
    {
      baseUrl: "https://client-tooling-eu-central-1.covatic.io",
      clientId: "5db1c6f588976c89fcb6bd70",
      displayName: "Bauer Media Mobile",
    },
    {
      baseUrl: "https://client-tooling-eu-central-1.covatic.io",
      clientId: "6311fd09a6829da1f8b81ca6",
      displayName: "Bauer Media Browser",
    },
  ],
  // This points to only browser with Octave (to make testing up to date segments much easier)
  developmentWebOnly: [
    {
      baseUrl: "https://client-tooling-eu-central-1.covatic.io",
      clientId: "63247594f1861e200ef5b102",
      displayName: "News UK Browser",
    },
    {
      baseUrl: "https://client-tooling-eu-central-1.covatic.io",
      clientId: "6311fd09a6829da1f8b81ca6",
      displayName: "Bauer Media Browser",
    },
  ],
  octave: [
    {
      baseUrl: "https://client-tooling-eu-west-1.covatic.io",
      clientId: "5db1c6f588976c89fcb6bd70",
      displayName: "Bauer Media Mobile",
    },
    {
      baseUrl: "https://client-tooling-eu-west-1.covatic.io",
      clientId: "6311fd09a6829da1f8b81ca6",
      displayName: "Bauer Media Browser",
    },
    {
      baseUrl: "https://client-tooling-wireless.covatic.io",
      clientId: "62a75e66ec19a5a161a15c97",
      displayName: "News UK Mobile",
    },
    {
      baseUrl: "https://client-tooling-wireless.covatic.io",
      clientId: "63247594f1861e200ef5b102",
      displayName: "News UK Browser",
    },
  ],
  sky: [
    {
      baseUrl: "https://client-tooling-eu-west-2.covatic.io",
      clientId: "627ba7d52f8b31d7cfa2a5f6",
      displayName: "Sky browser",
    },
  ],
  bbc: [
    {
      baseUrl: "https://client-tooling-bbc.covatic.io",
      clientId: "646f651b59194d51cf2576ac",
      displayName: "BBC browser",
    },
  ],
  nbcu: [
    {
      baseUrl: "https://client-tooling-nbcu.covatic.io",
      clientId: "636a384a472389133782e4d6",
      displayName: "NBCU browser",
    },
  ],
  //bauer ireland
  bauer: [
    {
      baseUrl: "https://client-tooling-bauerireland.covatic.io",
      clientId: "644949c30d627af07cd96f5d",
      displayName: "Bauer Media Ireland",
    },
  ],
};

function parseJwt(token: string): {email: string, exp: number} | undefined {
  var base64Url = token.split('.')[1]; 
  if (base64Url === undefined) {
    return
  }
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}


export const findClientInventory = (email: string): Array<Extract<keyof typeof ClientInventories, string>> => {
  // This function is going to be a little ... hacky. In an ideal world, the ID
  // token would contain this information, but since we don't have time to
  // implement JWT properly, I do a bit of gymnastics around selecting the
  // right client inventory for the user.
  // 
  // This remains secure as even if the UI selects the wrong client inventory,
  // the API won't allow them to access it.
  // 
  // Let's go.
  

  const environmentClientInventory = process.env["REACT_APP_CLIENT_INVENTORY"]
  if (!!environmentClientInventory) {
    return [environmentClientInventory]
  }
  
  if (window.location.host.startsWith("octave")) {
    return ['octave']
  }

  if (window.location.host.startsWith("sky")) {
    return ['sky']
  }

  if (window.location.host.startsWith("bbc")) {
    return ['bbc']
  }

  if (window.location.host.startsWith("nbcu")) {
    return ['nbcu']
  }

  //For Bauer Ireland
  if (window.location.host.startsWith("bauer")) {
    return ['bauer']
  }

  if (email.endsWith('@example.com')) {
    return ['test']
  }
  
  // If it's an amplify build
  if (window.location.host.endsWith('d3byeamkpci9x1.amplifyapp.com') || window.location.host.startsWith('dev-audience-builder-web-only')) {
    return ['developmentWebOnly']
  }

  if (window.location.host.startsWith("dev-mobile")) {
    return ['mobiletestapp']
  }
  
  return ['development', 'mobiletestapp', 'test']
}

export const findGPTCompanyPrompt = (): string => {
  if (window.location.host.startsWith("octave")) {
    return "Octave Audio selling audio advertising inventory on behalf of Bauer radio stations across UK"
  }

  if (window.location.host.startsWith("sky")) {
    return "Sky Media selling advertising inventory across Sky UK"
  }

  if (window.location.host.startsWith("bbc")) {
    return "the BBC selling advertising inventory across USA"
  }

  if (window.location.host.startsWith("bauer")) {
    return "Bauer selling advertising inventory across Ireland"
  }

  if (window.location.host.startsWith("dev") || window.location.host.startsWith('localhost')) {
    return "Acme Corporation selling advertising inventory across Acme radio stations and websites"
  }

  return "an ad funded media company"
}

export const selectClaims = createSelector(
  [(state: RootState) => state.userStatus.idToken],
  (idToken): {
    email: string,
    exp: number,
    allowedClientInventories: string[],
    gptCompanyPrompt: string,
  } | null => {
    if (idToken === null) {
      return null
    }
    const claims = parseJwt(idToken)
    if (claims === undefined) {
      // If this happens, something has gone _badly_ wrong.
      return null
    }
    const allowedClientInventories = findClientInventory(claims.email)
    return {
      ...claims,
      allowedClientInventories,
      gptCompanyPrompt: findGPTCompanyPrompt(),
    }
  }
)

export function selectClientInventory(state: RootState): APIConfig[] {
  const claims = selectClaims(state)
  if (claims === null) {
    return []
  }
  return ClientInventories[claims.allowedClientInventories[0]]
}
