import { LinearProgress } from "@mui/material";
import { QueryStatus } from "@reduxjs/toolkit/dist/query";
import React, { useRef, useState } from "react";
import { useAppSelector } from "../store/hooks";

export default function LoadingBar() {
  const isLoading = useAppSelector((state) => {
    return [
      ...Object.entries(state.api.mutations).filter(x => !x[0].startsWith("log")).map(x => x[1]),
      ...Object.values(state.api.queries),
    ].some((query) => {
      return query && query.status === QueryStatus.pending;
    });
  });

  if (!isLoading) {
    return <></>;
  }

  return (
    <LinearProgress
      data-loading-bar
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        zIndex: 999,
      }}
    />
  );
}
