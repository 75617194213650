/* Calculated with the following python code:
import csv

csv = list(csv.DictReader(open("Acorn_traits_above_UK_average.csv")))

x = defaultdict(lambda: [])

for row in csv:
    items = list(row.items())
    acorn_code = int(items[0][1])
    for column_title, column_value in items[1:]:
        print(column_title, column_value)
        if column_value == 'True':
            x[column_title].append(acorn_code)
*/

import acornTraitsAboveUKAverage from "./acorn_traits_above_uk_average.json";

export type AcornTrait = keyof typeof acornTraitsAboveUKAverage;

// type AcornTraitsAboveUKAverage  = {
//     [k in AcornTraits]: Set<number>;
// }

export function isAcornTrait(t: string): t is AcornTrait {
    return !!acornTraitsAboveUKAverage[t as AcornTrait]
}

// const acornTraitsAboveUKAverageWithSets = {} as AcornTraitsAboveUKAverage

// Object.keys(acornTraitsAboveUKAverage).forEach((k, _index, _array) => {
//     if (!isAcornTrait(k)) {
//         throw(`unknown acorn trait "${k}" given`)
//     }
//     acornTraitsAboveUKAverageWithSets[k] = new Set(acornTraitsAboveUKAverage[k])
// })

// const intersection = (a: Set<number>, b: Set<number>) => new Set([...a].filter(x => b.has(x)));

// TODO test
export function getAcornCodesFromAcornTraitsWithOr(
  acornTraits: AcornTrait[]
): number[] {
  return [
    ...new Set(
      acornTraits.reduce((prev, curr) => {
        return [...prev, ...acornTraitsAboveUKAverage[curr]];
      }, [] as number[])
    ),
  ];
}

export function getAcornCodesFromAcornTraitsWithAnd(
  acornTraits: AcornTrait[]
): number[] {
  if (acornTraits.length === 0) {
    return []
  }
  const firstAcornCodes = acornTraitsAboveUKAverage[acornTraits.slice(0, 1)[0]]
  if (acornTraits.length === 1) {
    return firstAcornCodes
  }
  return acornTraits.slice(1).reduce((prev, curr) => {
        return prev.filter(x => acornTraitsAboveUKAverage[curr].includes(x)) ;
      }, firstAcornCodes)
}
