import {createSelector} from "@reduxjs/toolkit";
import {QueryStatus} from "@reduxjs/toolkit/dist/query";
import {isAfter} from "date-fns";
import {api, SegmentType, useGetSegmentsQuery} from "../services/api";
import {RootState, store} from "../store";
import {APIConfig, ClientInventories} from "./clientInventories";
import {isIdTokenExpired} from "./userStatusSlice";
import experian_groups_and_types from "./experian_groups_and_types.json";

const selectProspectiveSegmentsResult = api.endpoints.getSegments.select();
const selectCovaticSegmentsResult = api.endpoints.getProfiles.select();
const selectIdToken = (state: RootState) => state.userStatus.idToken;
type GroupsAndTypes = {
    [key: string]: string;
};

const emptySegments = {} as { [k: string]: string };

export const selectSegmentDisplayNames = createSelector(
    [selectProspectiveSegmentsResult, selectCovaticSegmentsResult],
    (segmentsResult, covaticSegments) => {
        console.log("CALLED");
        if (
            segmentsResult.data === undefined ||
            covaticSegments.data === undefined
        ) {
            console.log("RETURNING EMPTY SEGMENTS");
            store.dispatch(api.endpoints.getSegments.initiate());
            return emptySegments;
        }

        const prospectiveSegments = Object.fromEntries(
            segmentsResult.data
                .map((x) => {
                    if (x.source === SegmentType.PROSPECTIVE_ACORN || x.source === SegmentType.MOSAIC_USA_VARIABLE) {
                        return [x.segment, x.segment.slice(3).replace(/\|/g, "  〉")];
                    }
                    if (x.source === SegmentType.GENDER) {
                        return [x.segment, "Gender: " + x.segment.slice(3)]
                    }
                    if (x.source === SegmentType.AGE) {
                        return [x.segment, "Age: " + x.segment.slice(3)]
                    }
                    if (x.source === SegmentType.LIVES_IN) {
                        return [x.segment, "Lives: " + x.segment.slice(3)]
                    }
                    if (x.source === SegmentType.RELEASED) {
                        // We'll deal with released segments in a separate loop
                        return [];
                    }


                    if (x.source === SegmentType.MOSAIC_USA_TYPE || x.source === SegmentType.MOSAIC_USA_GROUP) {
                        if (x.segment.includes("Mosaic USA Group ") || x.segment.includes("Mosaic USA Type ")) {
                            let segmentCat = ""
                            if (x.segment.includes("Mosaic USA Group ")) {
                                segmentCat = x.segment.split("Mosaic USA Group ")[1].trim()
                            }
                            if (x.segment.includes("Mosaic USA Type ")) {
                                segmentCat = x.segment.split("Mosaic USA Type ")[1].trim()
                            }
                            const experianGroupsAndTypes: GroupsAndTypes = experian_groups_and_types
                            return [x.segment, `${x.segment.replace(/^(MUG|MUT)(?=[A-Z])/, "")}: ${experianGroupsAndTypes[segmentCat]}`];
                        }
                    }

                    // If we are at this point, we have no idea how to format this so we
                    // just strip the first 3 characters
                    return [x.segment, x.segment.slice(3)];
                })
                .filter((x) => x.length === 2)
        );

        // OK, so the prospective profiles are done, time to do the released profiles
        const releasedSegments: { [k: string]: string } = Object.fromEntries(
            covaticSegments.data.map((x) => [
                SegmentType.RELEASED + x.metadata.name,
                x.metadata.annotations["insights/display-name"] ?? x.metadata.name,
            ])
        );
        const retval: { [k: string]: string } = {
            ...prospectiveSegments,
            ...releasedSegments,
        };
        return retval;
    }
);

function parseJwt(token: string): { email: string; exp: number } | undefined {
    var base64Url = token.split(".")[1];
    if (base64Url === undefined) {
        return;
    }
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );

    return JSON.parse(jsonPayload);
}
