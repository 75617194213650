/* eslint-disable no-restricted-globals */
import {
    createSelector,
    isRejectedWithValue,
    Middleware,
    MiddlewareAPI,
} from "@reduxjs/toolkit";
import {createApi, fetchBaseQuery, retry} from "@reduxjs/toolkit/query/react";
import ObjectID from "bson-objectid";
import SnackbarUtilsConfigurator from "../components/SnackbarUtilsConfigurator";
import {RootState} from "../store";
import {
    APIConfig,
    ClientInventories,
    selectClientInventory,
} from "../store/clientInventories";
import {setIdToken} from "../store/userStatusSlice";

import Profile from "./profile";

// TODO put this in some kind of config that is got from the JWT claims of the user.
// It's multiple API time.

// This should be in a JWT claim
// const apiConfigs: APIConfig[] = ;

// export enum SegmentType {
//   RELEASED = "REL",
//   PROSPECTIVE_ACORN = "PRO",
//   UNKNOWN = "UNKNOWN",
// }

export const SegmentType = {
    RELEASED: "REL",
    PROSPECTIVE_ACORN: "PRO",
    GENDER: "GEN",
    AGE: "AGE",
    ACORN_TYPE: "ACT",
    ACORN_GROUP: "ACG",
    ACORN_CATEGORY: "ACC",
    MOSAIC_USA_TYPE: "MUT",
    MOSAIC_USA_GROUP: "MUG",
    MOSAIC_USA_VARIABLE: "MUV",
    LIVES_IN: "LIN",
    UNKNOWN: "UNKNOWN",
} as const;
export type SegmentTypeValue = typeof SegmentType[keyof typeof SegmentType];

const reverseSegmentType = Object.fromEntries(
    Object.entries(SegmentType).map((x) => [x[1], x[0]])
);

export function isSegmentTypeValue(k: string): k is SegmentTypeValue {
    return !!reverseSegmentType[k];
}

export interface Segment {
    segment: string;
    reach: number;
    avails: number;
    code?: string;
    keywords?: string[];
    source: SegmentTypeValue;

    // This value is for various search algorithms across the app and should
    // contain a santisied string of the segment member varible that's easy to
    // search
    searchValue?: string;
}

// to3sf takes the number down to 3 significant figures.
//
// Since the API is very estimatey, it never makes sense to display very
// accurate numbers basically anywhere within the site, so we remove the
// precision at the RTK query level
const to3sf = (x: number): number => +x.toPrecision(3);

// const hashparams = new URLSearchParams(location.hash.substring(1));
// export const AUTH_TOKEN = hashparams.get("id_token") ?? localStorage.getItem('id_token')
// if (AUTH_TOKEN !== null) {
//   localStorage.setItem("id_token", AUTH_TOKEN)
// }

// taken from: https://stackoverflow.com/a/38552302/2060855
export function parseJwt(
    token: string
): { email: string; exp: number } | undefined {
    var base64Url = token.split(".")[1];
    if (base64Url === undefined) {
        return;
    }
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );

    return JSON.parse(jsonPayload);
}

// This is a *terrible* way to do this logic, I should really keep it in the store ... I'll fix it when I fix the login logic.
//
// TODO fix it
// export const CURRENT_USER = parseJwt(AUTH_TOKEN ?? "")?.email ?? "unknown"
// export const EXPIRY_TIME = new Date(parseJwt(AUTH_TOKEN ?? "")?.exp ?? 0)

const getErrorCodesFromManyRequests = (requests: any[]) => {
    if (requests.map((r) => !r.meta?.response?.ok).some(Boolean)) {
        const status: number =
            requests.filter((r) => (r.meta?.response?.status ?? 600) >= 400)[0].meta
                ?.response?.status ?? 600;
        return {
            error: {
                data: "something went wrong, returning first error code",
                status,
            },
        };
    }

    return;
};

const createError = (msg: string, status: number) => ({
    error: {data: msg, status},
});

/**
 * Log a warning and show a snackbar!
 */
export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (
    next
) => (action) => {
    // const { enqueueSnackbar } = useSnackbar()

    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
        if (action.payload.status === 401) {
            console.log(new Date());
            console.log((api.getState() as RootState).userStatus.idToken);
            // This action should trigger the logout modal to do it's thing
            //
            // TODO make it so that the page behind the modal doesn't freak out
            // (possibly have the logout modal cover or hide the state behind it)
            if ((api.getState() as RootState).userStatus.idToken !== null) {
                api.dispatch(setIdToken(null));
            }
            return next(action);
        }

        // This is not ideal, preferably the error will describe what has
        // _actually_ gone wrong to the user, rather than giving a vague error.
        //
        // TODO provide more meaningful errors to users, possibly by showing
        // errors per *page* rather than globally like this.
        SnackbarUtilsConfigurator.error(
            `Oops, something went wrong! Technical details: ${JSON.stringify(
                action?.error
            )}`
        );
    }

    return next(action);
};

// Taken heavy inspiration from: https://redux-toolkit.js.org/rtk-query/usage/customizing-queries
const staggeredBaseQuery = retry(
    fetchBaseQuery({
        prepareHeaders: (headers, api) => {
            headers.set(
                "Authorization",
                "" + (api.getState() as RootState).userStatus.idToken
            );
            return headers;
        },
    }),
    {
        // This backoff is taken from https://github.com/reduxjs/redux-toolkit/blob/2ff1fa4364ce0dfa82dbbd3187aa0c63e57d5582/packages/toolkit/src/query/retry.ts
        // and then edited to make our tests faster *only* when the env variable CI is set to "true"
        backoff: async (attempt, maxRetries) => {
            const attempts = Math.min(attempt, maxRetries);

            const isInTest = !!process.env["REACT_APP_TEST_WITH_FAST_RETRIES"];
            const timeout = isInTest
                ? 10
                : ~~((Math.random() + 0.4) * (300 << attempts)); // Force a positive int in the case we make this an option
            await new Promise((resolve) =>
                setTimeout((res: any) => resolve(res), timeout)
            );
        },
    }
);

export const api = createApi({
    baseQuery: staggeredBaseQuery,
    tagTypes: ["Segment"],
    endpoints: (builder) => ({
        getReachAndAvails: builder.query<any,
            { profiles: string[]; operator: "AND" | "OR" }>({
            // Keep the unused data for an hour in case someone leaves it open for a while
            keepUnusedDataFor: 60 * 60,
            async queryFn({profiles, operator}, api, _extraOptions, baseQuery) {
                if (profiles.length === 0) {
                    return {data: []};
                }

                const apiConfigs = selectClientInventory(api.getState() as RootState);
                if (apiConfigs === null) {
                    return createError("Unable to get the client inventory", 601);
                }

                const requests = await Promise.all(
                    apiConfigs.map((config: APIConfig) =>
                        baseQuery({
                            url:
                                config.baseUrl +
                                `/estimator/v1/estimation?${profiles
                                    .map((x) => "profile=" + encodeURIComponent(x))
                                    .join("&")}&client_id=${
                                    config.clientId
                                }&logical_operator=${operator}`,
                        })
                    )
                );

                // If any of the requests are not OK, something went wrong
                const possibleError = getErrorCodesFromManyRequests(requests);
                if (possibleError !== undefined) {
                    return possibleError;
                }

                const datas = requests.map((r) => r.data as ReachAndAvailsDatum[]);
                const data = datas
                    .reduce(
                        (prev?: ReachAndAvailsDatum[], curr?: ReachAndAvailsDatum[]) => {
                            // For each client, we add the graphs
                            //
                            // OK, so as far as I can tell, we should _never_ get undefined for prev,
                            // but Typescript thinks we might so I put question marks by prev in the
                            // argument
                            return curr?.map((v, i) => ({
                                "num of weeks":
                                    prev?.[i]?.["num of weeks"] ?? v["num of weeks"],
                                reach: (prev?.[i]?.reach ?? 0) + v.reach,
                                avails: (prev?.[i]?.avails ?? 0) + v.avails,
                            }));
                        },
                        []
                    )
                    ?.map((x: ReachAndAvailsDatum) => ({
                        ...x,
                        reach: to3sf(x.reach),
                        avails: to3sf(x.avails),
                    }));

                return {data};
            },
        }),
        getSegments: builder.query<Segment[], void>({
            // query: () => `/estimator/v1/segments?client_id=${config.clientId}`,
            async queryFn(_arg, api, _extraOptions, baseQuery) {
                const apiConfigs = selectClientInventory(api.getState() as RootState);
                if (apiConfigs === null) {
                    return createError("Unable to get the client inventory", 601);
                }

                const requests = await Promise.all(
                    apiConfigs.map((config: APIConfig) =>
                        baseQuery({
                            url:
                                config.baseUrl +
                                `/estimator/v1/segments?client_id=${config.clientId}`,
                        })
                    )
                );

                // If any of the requests are not OK, something went wrong
                const possibleError = getErrorCodesFromManyRequests(requests);
                if (possibleError !== undefined) {
                    return possibleError;
                }

                try {
                    // OK, let's do our dance
                    //
                    // Here, we remove our magic string at the start of name and replace it
                    // with the type in the object itself. Is it good code? I don't think
                    // so. But it's hopefully working code
                    //
                    // TODO make the API to this
                    const datas: Segment[][] = requests
                        .map((r) => r.data as Segment[])
                        .map(
                            // mapping over clients
                            (clientData) =>
                                clientData.map(
                                    (s: Segment): Segment => {
                                        // Here
                                        const possibleSegmentType:
                                            | SegmentTypeValue
                                            | string = s.segment.slice(0, 3);
                                        const source: SegmentTypeValue = isSegmentTypeValue(
                                            possibleSegmentType
                                        )
                                            ? possibleSegmentType
                                            : SegmentType.UNKNOWN;

                                        return {
                                            ...s,
                                            source,
                                            searchValue: s.segment
                                                .slice(3)
                                                .replace(/[^A-Za-z0-9]/g, " "),
                                        };
                                    }
                                )
                        );

                    const allSegmentDatasMaps: { [k: string]: Segment }[] = datas.map(
                        (v) => {
                            const entries = v?.map((x: Segment) => [x.segment, x]) ?? [];
                            return Object.fromEntries(entries);
                        }
                    );

                    const data: Segment[] = [];

                    const segmentNamesCommonBetweenClients = allSegmentDatasMaps
                        .slice(1)
                        .reduce(
                            (prev, curr) => prev.filter((x) => Object.keys(curr).includes(x)),
                            Object.keys(allSegmentDatasMaps[0])
                        );

                    for (let name of segmentNamesCommonBetweenClients) {
                        // Here, we reduce over clients
                        const s: Segment = allSegmentDatasMaps.reduce(
                            (prev: Segment, curr: { [k: string]: Segment }) => ({
                                ...prev,
                                avails: curr[name].avails + prev.avails,
                                reach: curr[name].reach + prev.reach,
                            }),
                            allSegmentDatasMaps[0][name]
                        );

                        // If the segment is one of the rare segments that's just a 'null'
                        // segment (i.e. one that the SDKs return when they don't know what
                        // to return), don't add it to the data
                        if (
                            s.segment === "ACTAcorn Type -1" ||
                            s.segment === "ACGAcorn Group -1" ||
                            s.segment === "ACCAcorn Category -1" ||
                            s.segment === "GENunknown" ||
                            s.segment === "AGEunknown"
                        ) {
                            continue;
                        }

                        data.push({
                            ...s,
                            reach: to3sf(s.reach),
                            avails: to3sf(s.avails),
                        });
                    }

                    return {data};
                } catch (_e) {
                    return {
                        error: {
                            data:
                                "something went wrong, perhaps the API returned an unexpected shape of data?",
                            status: 601,
                        },
                    };
                }
            },
        }),
        // This is a query that's a POST because we need a body so we use a POST
        createChatCompletion: builder.query<any, any>({
            async queryFn(body, api, _extraOptions, baseQuery) {
                // all the promises
                // Replace all the client IDs with the ones that we need
                const apiConfigs = selectClientInventory(api.getState() as RootState);
                if (apiConfigs === null) {
                    return createError("Unable to get the client inventory", 601);
                }

                const firstAPIConfig = apiConfigs[0];

                const request = await baseQuery({
                    url: firstAPIConfig.baseUrl + `/openai/v1/chatcompletions`,
                    method: "POST",
                    body: body,
                });

                return request;
            },
            keepUnusedDataFor: 60 * 60, // one hour
        }),
        logChatChatCompletionArgs: builder.mutation<any, any>({
            async queryFn(body, api, _extraOptions, baseQuery) {
                if (body === "") {
                    throw "empty body given to function";
                }
                // all the promises
                // Replace all the client IDs with the ones that we need
                const apiConfigs = selectClientInventory(api.getState() as RootState);
                if (apiConfigs === null) {
                    return createError("Unable to get the client inventory", 601);
                }

                const firstAPIConfig = apiConfigs[0];

                const request = await baseQuery({
                    url: firstAPIConfig.baseUrl + `/openai/v1/log`,
                    method: "POST",
                    body: body,
                });

                return request;
            },
        }),
        getOpenAIToken: builder.query<any, void>({
            async queryFn(_, api, _extraOptions, baseQuery) {
                // all the promises
                const apiConfigs = selectClientInventory(api.getState() as RootState);
                if (apiConfigs === null) {
                    return createError("Unable to get the client inventory", 601);
                }

                const firstAPIConfig = apiConfigs[0];

                const request = await baseQuery({
                    url: firstAPIConfig.baseUrl + `/openai/v1/token`,
                    method: "GET",
                });

                return request;
            },
            keepUnusedDataFor: 30 * 24 * 60 * 60, // 30 days, this should rarely change, if ever
        }),
        releaseProfile: builder.mutation<null, string>({
            async queryFn(name, api, _extraOptions, baseQuery) {
                // all the promises
                // Replace all the client IDs with the ones that we need
                const apiConfigs = selectClientInventory(api.getState() as RootState);
                if (apiConfigs === null) {
                    return createError("Unable to get the client inventory", 601);
                }

                const requests = await Promise.all(
                    apiConfigs.map((config: APIConfig) => {
                        return baseQuery({
                            url:
                                config.baseUrl +
                                `/segment/v1/release?client_id=${config.clientId}&name=${name}&segment_version=latest`,
                            method: "POST",
                        });
                    })
                );

                // If any of the requests are not OK, something went wrong
                const possibleError = getErrorCodesFromManyRequests(requests);
                if (possibleError !== undefined) {
                    return possibleError;
                }

                return {data: null};
            },
            invalidatesTags: ["Segment"],
            extraOptions: {
                // This POST should never be retried automatically, as it is not
                // guaranteed to be idempotent
                maxRetries: 0,
            },
        }),
        createProfile: builder.mutation<null, Profile>({
            async queryFn(profile, api, _extraOptions, baseQuery) {
                // all the promises
                // Replace all the client IDs with the ones that we need
                const apiConfigs = selectClientInventory(api.getState() as RootState);
                if (apiConfigs === null) {
                    return createError("Unable to get the client inventory", 601);
                }

                const requests = await Promise.all(
                    apiConfigs.map((config: APIConfig) => {
                        // Prepare the profile for this client
                        const profileCopy = JSON.parse(JSON.stringify(profile));
                        profileCopy.metadata.client_id = config.clientId;

                        return baseQuery({
                            url: config.baseUrl + `/segment/v1/`,
                            method: "POST",
                            body: JSON.stringify(profileCopy),
                        });
                    })
                );

                // If any of the requests are not OK, something went wrong
                const possibleError = getErrorCodesFromManyRequests(requests);
                if (possibleError !== undefined) {
                    return possibleError;
                }

                return {data: null};
            },
            invalidatesTags: ["Segment"],
            extraOptions: {
                // This POST should never be retried automatically, as it is not
                // guaranteed to be idempotent
                maxRetries: 0,
            },
        }),
        deleteProfile: builder.mutation<null, string>({
            async queryFn(metadataName, api, _extraOptions, baseQuery) {
                const apiConfigs = selectClientInventory(api.getState() as RootState);
                if (apiConfigs === null) {
                    return createError("Unable to get the client inventory", 601);
                }

                const requests = await Promise.all(
                    apiConfigs.map((config: APIConfig) => {
                        return baseQuery({
                            url:
                                config.baseUrl +
                                `/segment/v1/?client_id=${config.clientId}&name=${metadataName}`,
                            method: "DELETE",
                        });
                    })
                );

                // If any of the requests are not OK, something went wrong
                const possibleError = getErrorCodesFromManyRequests(requests);
                if (possibleError !== undefined) {
                    return possibleError;
                }

                return {data: null};
            },
            invalidatesTags: ["Segment"],
        }),
        unreleaseProfile: builder.mutation<null, string>({
            async queryFn(metadataName, api, _extraOptions, baseQuery) {
                const apiConfigs = selectClientInventory(api.getState() as RootState);
                if (apiConfigs === null) {
                    return createError("Unable to get the client inventory", 601);
                }

                const requests = await Promise.all(
                    apiConfigs.map((config: APIConfig) => {
                        return baseQuery({
                            url:
                                config.baseUrl +
                                `/segment/v1/unrelease?client_id=${config.clientId}&name=${metadataName}`,
                            method: "POST",
                        });
                    })
                );

                // If any of the requests are not OK, something went wrong
                const possibleError = getErrorCodesFromManyRequests(requests);
                if (possibleError !== undefined) {
                    return possibleError;
                }

                return {data: null};
            },
            invalidatesTags: ["Segment"],
        }),

        getProfiles: builder.query<Profile[], void>({
            // query: () => `/segment/v1/?client_id=${config.clientId}`,
            providesTags: ["Segment"],
            async queryFn(_arg, api, _extraOptions, baseQuery) {
                const apiConfigs = selectClientInventory(api.getState() as RootState);
                if (apiConfigs === null) {
                    return createError("Unable to get the client inventory", 601);
                }

                const requests = await Promise.all(
                    apiConfigs.map((config: APIConfig) => {
                            return baseQuery({
                                url:
                                    config.baseUrl +
                                    `/segment/v1/?client_id=${config.clientId}&segment_version=latest`,
                            })
                        }
                    )
                );

                // If any of the requests are not OK, something went wrong
                const possibleError = getErrorCodesFromManyRequests(requests);
                if (possibleError !== undefined) {
                    return possibleError;
                }

                const datas: Profile[][] = requests.map((r) => r.data as Profile[]);
                const allProfileDatasMaps: { [k: string]: Profile }[] = datas.map(
                    (v) => {
                        console.log(v);
                        const entries =
                            v?.map((x: Profile) => [
                                // x.metadata.annotations?.ad_campaign_estimator?.sync_id,
                                x.metadata.name,
                                x,
                            ]) ?? [];
                        return Object.fromEntries(entries);
                    }
                );

                const dataGroupedByName: {
                    [k: string]: Profile[];
                } = allProfileDatasMaps.reduce(
                    (
                        prev: { [k: string]: Profile[] },
                        curr: { [k: string]: Profile }
                    ) => {
                        for (let k of Object.keys(curr)) {
                            if (k === "undefined") {
                                continue;
                            }
                            if (prev[k] === undefined) {
                                prev[k] = [];
                            }

                            prev[k].push(curr[k]);
                        }

                        return prev;
                    },
                    {}
                );

                // Filter down to the profiles that have a matching name in all the applicable api configs
                const data: Profile[] = Object.values(dataGroupedByName)
                    .map((v) => (v.length === apiConfigs.length ? v[0] : false))
                    .filter((x): x is Profile => !!x)
                    .map(
                        (profile): Profile => ({
                            ...profile,
                            metadata: {
                                ...profile.metadata,
                                annotations: {
                                    "insights/last-updated":
                                        profile.metadata.annotations["insights/last-updated"] ??
                                        ObjectID(profile._id ?? "")
                                            .getTimestamp()
                                            .toISOString(),
                                    ...profile.metadata.annotations,
                                    ad_campaign_estimator: {
                                        // This should always be present if we've got to this stage, but just in case I fill it with null values
                                        ...(profile.metadata.annotations.ad_campaign_estimator ?? {
                                            created_by: "unknown",
                                            estimated_avails: 0,
                                            estimated_duration: 0,
                                            estimated_reach: 0,
                                            sync_id: "",
                                            traits_included: [],
                                        }),
                                    },
                                },
                            },
                        })
                    );

                return {data};
            },
        }),
    }),
});

interface ReachAndAvailsDatum {
    "num of weeks": number;
    reach: number;
    avails: number;
}

export const {
    useGetReachAndAvailsQuery,
    useGetSegmentsQuery,
    useCreateProfileMutation,
    useReleaseProfileMutation,
    useUnreleaseProfileMutation,
    useDeleteProfileMutation,
    useGetProfilesQuery,
    useCreateChatCompletionQuery,
    useGetOpenAITokenQuery,
    useLogChatChatCompletionArgsMutation,
} = api;
