import React from "react";
import Box from "@mui/material/Box";

import SegmentTable from "./SegmentTable";
import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const List = () => {
  
  return (
    <Box>
      {/* <Box
        sx={{
          textAlign: "right",
          pt: 1,
          pr: 5,
        }}
      >
        <Link to={`../segment`}>
          <Button variant="contained" startIcon={<Add />} sx={{ m: 1 }}>
            Create
          </Button>
        </Link>
      </Box> */}
      <Box
        sx={{
          p: 5,
        }}
      >
        <SegmentTable />
      </Box>
    </Box>
  );
};

export default List;
