import { Alert, Button, ButtonProps } from "@mui/material";
import React, { useMemo } from "react";
import { Segment, useGetReachAndAvailsQuery } from "../../services/api";

const SubmitButton = (
  props: ButtonProps & { _segments: Segment[]; _operator: "AND" | "OR" }
) => {
  const { data } = useGetReachAndAvailsQuery({
    profiles: props._segments?.map((x: Segment) => x.segment) ?? [],
    operator: props._operator,
  });
  
  // TODO have a warning that turns up if too many ACORN traits have been added
  // while using AND?
  const isReachZero = data && data.length > 0 && data[0].reach === 0
  const buttonProps = {
    ...props,
    disabled: props.disabled || isReachZero
  }
  return (
    <>
      {isReachZero ? (
        <Alert severity="error" sx={{ mb: 1 }}>
            This combination of traits has dropped the reach estimate to 0.<br/><br/>Try reducing the number of traits or changing the Selection criteria.
        </Alert>
      ) : (
        <></>
      )}
      <Button {...buttonProps}></Button>
    </>
  );
};

export default SubmitButton;
