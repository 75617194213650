import * as React from "react";

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  useTheme,
} from "@mui/material";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import SnackbarUtilsConfigurator from "../SnackbarUtilsConfigurator";
import { useDeleteProfileMutation } from "../../services/api";
import { QueryStatus } from "@reduxjs/toolkit/dist/query";

export const DeleteDialog = ({
  open,
  onClose,
  segments,
}: {
  open: boolean;
  onClose: any;
  segments: { code: string; name: string }[];
}) => {
  const theme = useTheme();
  const [deleteProfile, { status }] = useDeleteProfileMutation();

  return (
    <Dialog open={open} onClose={onClose} >
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Box sx={{ flexGrow: 1 }}>
            Do you want to delete the following segment
            {segments.length > 1 ? "s" : ""}:&nbsp;
            <MaterialReactTable
              // memoMode="table-body"
              muiTableHeadCellProps={{
                sx: {
                  color: theme.palette.text.secondary,
                },
              }}
              muiTableBodyCellProps={{
                sx: {
                  color: theme.palette.text.secondary,
                },
              }}
              muiTableHeadRowProps={{
                sx: {
                  backgroundColor: "rgba(0, 0, 0, 0)",
                },
              }}
              muiTableBodyRowProps={{
                sx: {
                  backgroundColor: "rgba(0, 0, 0, 0)",
                },
              }}
              muiTablePaperProps={{
                elevation: 0,
                sx: {
                  mt: 2,
                  mb: 2,
                  color: "body.main",
                  // width: "99%",
                  border: 1,
                  borderColor: "#c4c4c4",
                  borderRadius: "4px",
                },
              }}
              columns={
                [
                  { accessorKey: "code", header: "Code", size: 4 },
                  { accessorKey: "name", header: "Name" },
                ] as MRT_ColumnDef<{ code: string; name: string }>[]
              }
              data={segments}
              enableColumnActions={false}
              enableColumnFilters={false}
              enableHiding={false}
              enableFullScreenToggle={false}
              enableDensityToggle={false}
              enableFilters={false}
              enableTableFooter={false}
              enablePagination={false}
              enableTableHead={true}
              enableTopToolbar={false}
              enableBottomToolbar={false}
              state={{
                density: "compact",
              }}
            />
            {/* <ul>
                  {segmentsToDelete.map(name => <li>{segmentDisplayNameMap["REL" + name]}</li>)}
                  </ul> */}
          </Box>
          <Typography>This action cannot be undone.</Typography>
          <Box></Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" sx={{ m: 1 }} onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="error"
          sx={{ m: 1 }}
          disabled={status === QueryStatus.pending}
          onClick={() => {
            // TODO the deletion
            // onClose()
            Promise.all(segments.map((s) => deleteProfile(s.code))).then(
              (d) => {
                onClose();
                if (d.some((x) => "error" in x)) {
                  SnackbarUtilsConfigurator.warning(
                    `Sorry, some segments were not deleted, please retry`
                  );
                  return;
                }
                SnackbarUtilsConfigurator.success(
                  `Segment${segments.length > 1 ? "s" : ""} ${segments
                    .map((s) => s.code)
                    .join(", ")} ${
                    segments.length > 1 ? "were" : "was"
                  } deleted`
                );
              }
            );
          }}
        >
          {status === QueryStatus.pending ? (
            <>
              <CircularProgress size={20} />
              &nbsp;
              <span>
                deleting {segments.length} segment
                {segments.length > 1 ? "s" : ""}
              </span>
            </>
          ) : (
            `yes, delete ${segments.length} segment${
              segments.length > 1 ? "s" : ""
            }`
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
