/* Calculated with the following python code:
import csv

csv = list(csv.DictReader(open("Acorn_traits_above_UK_average.csv")))

x = defaultdict(lambda: [])

for row in csv:
    items = list(row.items())
    acorn_code = int(items[0][1])
    for column_title, column_value in items[1:]:
        print(column_title, column_value)
        if column_value == 'True':
            x[column_title].append(acorn_code)
*/

import mosaicUSAVariablesAboveUKAverage from "./mosaic_us_above_us_average.json";

export type MosaicUSAVariable = keyof typeof mosaicUSAVariablesAboveUKAverage;


export function isMosaicUSAVariable(t: string): t is MosaicUSAVariable {
    return !!mosaicUSAVariablesAboveUKAverage[t as MosaicUSAVariable]
}


// TODO test
export function getMosaicUSATypesFromMosaicUSAVariablesWithOr(
  mosaicUSAVariables: MosaicUSAVariable[]
): string[] {
  return [
    ...new Set(
      mosaicUSAVariables.reduce((prev, curr) => {
        return [...prev, ...mosaicUSAVariablesAboveUKAverage[curr]];
      }, [] as string[])
    ),
  ];
}

export function getMosaicUSATypesFromMosaicUSAVariablesWithAnd(
  mosaicUSAVariables: MosaicUSAVariable[]
): string[] {
  if (mosaicUSAVariables.length === 0) {
    return []
  }
  const firstMosaicUSATypes = mosaicUSAVariablesAboveUKAverage[mosaicUSAVariables.slice(0, 1)[0]]
  if (mosaicUSAVariables.length === 1) {
    return firstMosaicUSATypes
  }
  return mosaicUSAVariables.slice(1).reduce((prev, curr) => {
        return prev.filter(x => mosaicUSAVariablesAboveUKAverage[curr].includes(x)) ;
      }, firstMosaicUSATypes)
}
