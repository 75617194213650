import React from "react";
import { Chip, } from "@mui/material";

export const LiveIcon = React.memo(() => {
  return <Chip label="LIVE" data-testid="LiveIcon" title="This segment has been published" size="small"  sx={{
    backgroundColor: '#1F6ADA',
    color: '#fff'
  }}/>
});

export default LiveIcon;
