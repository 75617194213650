const englishList = (values: string[]) =>
  values.length === 1
    ? values[0]
    : values.slice(0, values.length - 1).join(", ") +
      " and " +
      values[values.length - 1];

export const whileEmphasisingText = (values: string[]) =>
  values.length === 0 ? "" : ", while emphasising " + englishList(values) + ", ";

export interface createPromptProps {
  writeAs: string,
  withATone: string,
  whileEmphasising: string[],
  segmentPrettyNames: string[],
  reachPerX: number,
  Xweeks: number,
  segmentName: string,
}

const nameSentence = (name: string) => (name ?? "") !== ""  ? `The segment is called ${name}. `  : ""
  
export const createPrompt = (props: createPromptProps): string => `Create ${props.writeAs} with a ${props.withATone} tone${whileEmphasisingText(props.whileEmphasising)} of the following segment. ${nameSentence(props.segmentName)}Users in this segment match at least one of the following traits:

${props.segmentPrettyNames.join("\n")}

The reach of the segment is estimated to be ${props.reachPerX.toLocaleString()} users ${props.Xweeks == 1 ? "per week" : "over a " + props.Xweeks.toLocaleString() + " week period"}.

Add a final paragraph to say: This segment was created using Covatic’s premium, private by design advertising technology.
`