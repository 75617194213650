import { MRT_ColumnDef } from "material-react-table";
import React, { useMemo } from "react";
import { Segment, useGetReachAndAvailsQuery } from "../../services/api";
import MaterialReactTable from "material-react-table";
import { Skeleton, useTheme } from "@mui/material";
import { FormDefaults } from "./constants";
import { createAdCampaignEstimatorMetadata, createCVCQLProfile } from "./cvcqlCreation";

const CVCQLPreview = ({
  values,
  currentUser,
}: {
  values: FormDefaults;
  currentUser: string
}) => {
  const { data, isFetching, status } = useGetReachAndAvailsQuery({
    profiles: values.selectedsegments.map((s) => s.segment),
    operator: values.operator,
  });

  if (isFetching && values.shouldIncludeAdCampaignEstimatorMetadata) {
    return <Skeleton variant="rectangular" height={1000}></Skeleton>
  }

  return (
    <pre>
      <code>
        {JSON.stringify(
          createCVCQLProfile(
            values as FormDefaults,
            values.shouldIncludeAdCampaignEstimatorMetadata
              ? createAdCampaignEstimatorMetadata(
                  values as FormDefaults,
                  data,
                  currentUser
                )
              : undefined
          ),
          null,
          2
        )}
      </code>
    </pre>
  );
};

export default CVCQLPreview;
