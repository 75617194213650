import React, {
  ChangeEvent,
  FocusEvent,
  useMemo,
  useState,
  useRef,
  useEffect,
  HTMLAttributes,
} from "react";
import Box from "@mui/material/Box";
import { Form, Field, FormSpy } from "react-final-form";

import SegmentPickerV1 from "./SegmentPickerV1";
import CvcTextField from "./CvcTextField";
import {
  api,
  parseJwt,
  Segment,
  SegmentType,
  useCreateProfileMutation,
  useGetProfilesQuery,
  useGetReachAndAvailsQuery,
  useGetSegmentsQuery,
} from "../services/api";
import {
  Alert,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  InputBaseComponentsPropsOverrides,
  InputLabel,
  InputProps,
  MenuItem,
  OutlinedInput,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import EstimationChart from "./Form/EstimationChart";
import EstimationTable from "./Form/EstimationTable";
import SubmitButton from "./Form/SubmitButton";
import { ArrowBack, ArrowForward, Save } from "@mui/icons-material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Profile, {
  ProfileAdCampaignEstimatorMetadata,
} from "../services/profile";
import SnackbarUtilsConfigurator from "./SnackbarUtilsConfigurator";
import { createForm } from "final-form";
import { useAppSelector } from "../store/hooks";
import { QueryStatus } from "@reduxjs/toolkit/dist/query";
import { store } from "../store";
import Editor from "react-simple-code-editor";
import { FormDefaults, operatorsText } from "./Form/constants";
import {
  createAdCampaignEstimatorMetadata,
  createCVCQLProfile,
} from "./Form/cvcqlCreation";
import { create } from "lodash";
import CVCQLPreview from "./Form/CVCQLPreview";
import AboutThisSegment from "./Form/AboutThisSegment";

const required = (value: string) => (value ? undefined : "Required");
const isNotEmpty = (value: any[]) =>
  value.length > 0 ? undefined : "Length must be greater than 0";

const marginsForTextFields = (hideChart: boolean) =>
  hideChart
    ? {
        xs: 4,
        md: 8,
        lg: 12,
        xl: 16,
      }
    : 4;

const HIDE_CHART_KEY = "hideChart";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ height: "100%", display: value === index ? "flex" : "none" }}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const MyForm = () => {
  // Router functions
  const navigate = useNavigate();
  const location = useLocation();
  const params: { metadataName?: string } = useParams();

  const theme = useTheme();

  const [hideChart, innnerSetHideChart] = useState(
    JSON.parse(localStorage.getItem(HIDE_CHART_KEY) ?? "false")
  );

  const setHideChart = (value: boolean) => {
    localStorage.setItem(HIDE_CHART_KEY, JSON.stringify(value));
    innnerSetHideChart(value);
  };

  const CURRENT_USER = useAppSelector(
    (state) =>
      (state.userStatus.idToken !== null
        ? parseJwt(state.userStatus.idToken)?.email
        : undefined) ?? "unknown"
  );

  // isLoading is a flag used to figure out whether the page should allow saves
  // or not
  const isLoading = useAppSelector((state) => {
    return [
      ...Object.values(state.api.mutations),
      ...Object.values(state.api.queries),
    ]
      .filter((x) => !x?.endpointName?.includes("Chat"))
      .some((query) => {
        return query && query.status === QueryStatus.pending;
      });
  });

  // RTK query values
  const {
    data: allSegments,
    isFetching: allSegmentsFetching,
    error: allSegmentsError,
    refetch: allSegmentsRefetch,
  } = useGetSegmentsQuery();
  const {
    data: getProfilesData,
    error: getProfilesError,
    isFetching: getProfilesIsFetching,
  } = useGetProfilesQuery();

  // RTK Query selectors
  const getProfilesSelector = useMemo<{ [metadataName: string]: Profile }>(
    () =>
      !!getProfilesData
        ? Object.fromEntries(getProfilesData.map((p) => [p.metadata.name, p]))
        : {},
    [getProfilesData]
  );

  const [
    createProfile,
    { isLoading: isLoadingProfileMutation },
  ] = useCreateProfileMutation();

  // We replace a use of the store with a use of a state variable (we don't need the store here)
  // const selectedSegments: any = useSelector((state: RootState) => state.selectedSegments)
  // const [selectedSegments, setSegments] = useState([] as Segment[]);

  // **** Handling editing
  // If there is no metadataName specified in the router params, the defaults
  // below are what we want. If not, there is logic later to fill the form with
  // values from the service layer.
  let formDefaults: FormDefaults = {
    name: "",
    description: "",
    targetduration: "4",
    selectedsegments: [],
    profileVersion: 1,
    released: false,
    deleted: false,
    operator: "OR",
    external: true,
    shouldIncludeAdCampaignEstimatorMetadata: true,
    cvcqlVersion: "1.2.0",
  };
  const [defaultsHaveBeenSet, setDefaultsHaveBeenSet] = useState(
    !params.metadataName
  );

  // TODO use localStorage here
  const [tabValue, setTabValue] = useState(
    +(localStorage.getItem("Form_tabValue") ?? 0)
  );
  useEffect(() => {
    localStorage.setItem("Form_tabValue", "" + tabValue);
  }, [tabValue]);

  // ******************************************
  // Doing some fancy form stuff
  // ******************************************

  const fetchAdCampaignEstimatorMetadata = async (
    values: FormDefaults
  ): Promise<ProfileAdCampaignEstimatorMetadata> => {
    const selectedSegments = values.selectedsegments;
    const args = {
      profiles: selectedSegments.map((x: Segment) => x.segment),
      operator: values.operator,
    };
    const getAndReachAvailsResult = store.dispatch(
      api.endpoints.getReachAndAvails.initiate(args)
    );
    let { data, status, error } = api.endpoints.getReachAndAvails.select(args)(
      store.getState()
    );

    function sleep(ms: number) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }

    while (status != QueryStatus.fulfilled) {
      ({ data, status, error } = api.endpoints.getReachAndAvails.select(args)(
        store.getState()
      ));
      if (error !== undefined) {
        // Code should never get here since in order to press the Update button
        // the getReachAndAvails endpoint should already be cached and
        // available to other parts of the app
        //
        // Just in case it does, I've put an error snackbar here
        SnackbarUtilsConfigurator.error(
          `Oops, something went wrong. Please try again in a few moments.`
        );
        throw "unable to resolve ad campaign estimator metadata, error: " +
          JSON.stringify(error);
      }
      await sleep(100);
    }

    // TODO check the status

    const adCampaignEstimatorMetadata = createAdCampaignEstimatorMetadata(
      values,
      data,
      CURRENT_USER
    );
    getAndReachAvailsResult.unsubscribe();

    return adCampaignEstimatorMetadata;
  };

  const formOnSubmit = async (values: FormDefaults) => {
    console.log("SUBMITTING THE FORM");
    console.log(values);

    let adCampaignEstimatorMetadata:
      | ProfileAdCampaignEstimatorMetadata
      | undefined = undefined;
    if (values.shouldIncludeAdCampaignEstimatorMetadata) {
      adCampaignEstimatorMetadata = await fetchAdCampaignEstimatorMetadata(
        values
      );
    }
    const profile = createCVCQLProfile(values, adCampaignEstimatorMetadata);
    console.log(profile);
    createProfile(profile).then((d) => {
      console.log("******");
      console.log(d);
      if ("error" in d) {
        return;
      }
      SnackbarUtilsConfigurator.success(
        `Segment "${profile.metadata.annotations["insights/display-name"]}" successfully saved!`
      );
      formInstance.current.reset({
        name: "",
        description: "",
        selectedsegments: [],
      });
      navigate("/");
    });

    return;
  };

  const formInstance = useRef(
    createForm({
      initialValues: {
        ...formDefaults,
      },

      onSubmit: formOnSubmit,
    })
  );

  // If the defaults haven't been set yet, attempt to do so
  if (!defaultsHaveBeenSet) {
    if (!!params.metadataName && !!getProfilesData && !!allSegments) {
      const profile = getProfilesSelector[params.metadataName];
      const traitsIncluded =
        profile.metadata.annotations.ad_campaign_estimator?.traits_included ??
        [];
      formDefaults = {
        name: profile.metadata.annotations["insights/display-name"] ?? "",
        description: profile.metadata.annotations["insights/description"] ?? "",
        targetduration:
          "" +
            profile.metadata.annotations.ad_campaign_estimator
              ?.estimated_duration ?? "4",
        selectedsegments: [
          ...allSegments.filter((s) =>
            traitsIncluded.find((x) => s.segment === x)
          ),
        ],
        profileVersion: profile.metadata.profile_version + 1,
        metadataName: profile.metadata.name,
        released: profile.metadata.flags.released,
        deleted: profile.metadata.flags.deleted,
        external: profile.metadata.annotations["sdk@is_external"],
        cvcqlVersion: profile.metadata.cvcql_version,

        operator: profile.query?.and ? "AND" : "OR",
        shouldIncludeAdCampaignEstimatorMetadata: !!profile.metadata.annotations
          .ad_campaign_estimator,
        slProfile: profile.metadata.annotations["sl@sl_profile"],
        isAssignedProfile:
          profile.metadata.annotations["sl@is_assigned_profile"],
        isInMoment: profile.metadata.annotations["sl@is_in_moment"],
      };

      formInstance.current.batch(() => {
        let k: keyof FormDefaults;
        for (k in formDefaults) {
          formInstance.current.change(k, formDefaults[k]);
        }
      });
      setDefaultsHaveBeenSet(true);
    }
  }

  // ******************************************
  // Doing some fancy form stuff
  // ******************************************

  const [shouldDisableSave, setShouldDisableSave] = useState(false);

  const renderLineChart = (
    selectedsegments: Segment[],
    targetduration: string,
    operator: "AND" | "OR"
  ) => {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          // opacity: isFetching ? 0.3 : 1,
        }}
        data-testid="EstimationChart"
      >
        {isAdvancedFieldsAllowed ? (
          <>
            <Alert
              severity="error"
              action={
                <Button color="inherit" onClick={() => navigate("/")}>
                  back to safety
                </Button>
              }
            >
              This page is for advanced usage, use with caution!
            </Alert>
            <Box
              sx={{
                textAlign: "left",
              }}
            >
              <FormSpy
                render={(values) => (
                  <CVCQLPreview
                    values={values.values as FormDefaults}
                    currentUser={CURRENT_USER}
                  />
                )}
              ></FormSpy>
            </Box>
          </>
        ) : (
          <EstimationChart
            showSkeleton={!defaultsHaveBeenSet}
            selectedsegments={selectedsegments}
            availsRef={0}
            durationRef={+targetduration}
            reachRef={0}
            operator={operator}
          />
        )}
      </Box>
    );
  };

  if (getProfilesError) {
    return (
      <Box>
        <Box
          sx={{
            textAlign: "left",
          }}
        >
          <Button sx={{ m: 1 }} onClick={() => navigate("/")}>
            <ArrowBack />
          </Button>
          {params.metadataName
            ? "Editing segment '" + params.metadataName + "'"
            : ""}
        </Box>
        <Box>
          <Alert severity="error" sx={{ ml: 5, mr: 5, textAlign: "left" }}>
            Hmm, something went wrong. Please try refreshing the page. If this
            problem persists, please contact Covatic directly
          </Alert>
        </Box>
      </Box>
    );
  }

  // If everything is going wrong, we should display a snackbar saying it's going wrong
  if (allSegmentsError) {
    return (
      <Box>
        <Box
          sx={{
            textAlign: "left",
          }}
        >
          <Button sx={{ m: 1 }} onClick={() => navigate("/")}>
            <ArrowBack />
          </Button>
          {params.metadataName
            ? "Editing segment '" + params.metadataName + "'"
            : ""}
        </Box>
        <Box>
          <Alert
            severity="error"
            action={
              <Button color="error" onClick={allSegmentsRefetch}>
                retry
              </Button>
            }
            sx={{
              ml: 5,
              mr: 5,
            }}
          >
            Ack, something went wrong on our end &ndash; If this problem
            persists, please contact Covatic directly
          </Alert>
        </Box>
      </Box>
    );
  }

  // TODO figure out how to have a list of advanced users, possibly in the JWT
  const isAdvancedUser = CURRENT_USER.endsWith("@covatic.com");
  const isAdvancedFieldsAllowed =
    location.pathname === "/segment-advanced" && isAdvancedUser;

  if (location.pathname === "/segment-advanced" && !isAdvancedUser) {
    return (
      <Alert color="warning" sx={{ m: 4 }}>
        Sorry, this page is restricted
      </Alert>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          textAlign: "left",
        }}
      >
        <Button sx={{ m: 1 }} onClick={() => navigate("/")}>
          <ArrowBack />
        </Button>
        {params.metadataName
          ? "Editing segment '" + params.metadataName + "'"
          : ""}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          // [isAdvancedFieldsAllowed ? "minHeight" : "height"]: "80vh",
          minHeight: "80vh",
          width: "100%",
        }}
      >
        {/* OK, this is a huge 'form'. It's actually more like a mini react
      thing. Because updates have to propogate in unusual ways and react was
      choosing to re-render the chart super optimistically (e.g. when the save
      button changed, it triggered a rerender of the entire page!), I use Form
      here to subscribe to specific values and only update parts of the UI
      *based on the subscription*. This means I lose some of the automatic
      magic of React, but also means that I get a massive performance increase
      (something like 10x or more improvement) which really helps it feel
      snappy*/}
        <Form
          form={formInstance.current}
          // It took me two hours to figure this out ... This shouldn't be
          // needed because it's in the form instance. It's story time.
          //
          // According to the types, createForm requires an onSubmit
          // function, so I added an onSubmit there. But the types _also_
          // require onChange on the Form component. So I removed it from the
          // createForm call and added it to the component. But then
          // createForm had a problem because it _requires_ onChange.
          //
          // Two hours later, I looked at the React Final Form code, and all
          // it does is attach the Form's onSubmit to the form instance, so I
          // added the same function here and typescript stopped complaining.
          // Since this function never changes, I think this is safe. I
          // should really report a bug with the types but I'm not sure I can
          // stand to make a minimum working example right now...
          onSubmit={formOnSubmit}
          subscription={{ submitting: true, pristine: true }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  height: "95%",
                  // In the advanced mode, it's more important to show all
                  // fields than to be pretty, so we ignore maxHeight if
                  // isAdavncedFieldsAllowed is set
                  ...(isAdvancedFieldsAllowed ? {} : { maxHeight: "80vh" }),
                  overflowY: "scroll",
                  overflowX: "none",
                  mt: 0,
                  ml: marginsForTextFields(hideChart),
                  mr: marginsForTextFields(hideChart),
                  mb: marginsForTextFields(hideChart),
                  maxWidth: hideChart ? "100vw" : { md: "33vw" },
                }}
              >
                <form onSubmit={handleSubmit} id="segmentCreationForm">
                  {isAdvancedFieldsAllowed ? (
                    <Box
                      sx={{
                        backgroundColor: "rgba(255 152 0 / 10%)",
                        textAlign: "left",
                        p: 2,
                        borderRadius: 1,
                      }}
                    >
                      <Typography variant="h6">Advanced fields</Typography>
                      <Field
                        name="metadataName"
                        validate={required}
                        subscription={{
                          value: true,
                          touched: true,
                          error: true,
                        }}
                      >
                        {({ input, meta }) => (
                          <Box>
                            <CvcTextField
                              showSkeleton={!defaultsHaveBeenSet}
                              {...input}
                              id="metadata-name"
                              label=".metadata.name (advanced)"
                              InputLabelProps={{ shrink: true }}
                              error={meta.touched && !!meta.error}
                              helperText={
                                meta.touched && meta.error
                                  ? ".metadata.name is required"
                                  : ""
                              }
                            />
                          </Box>
                        )}
                      </Field>
                      <Field
                        name="profileVersion"
                        validate={required}
                        subscription={{
                          value: true,
                          touched: true,
                          error: true,
                        }}
                      >
                        {({ input, meta }) => (
                          <Box>
                            <CvcTextField
                              showSkeleton={!defaultsHaveBeenSet}
                              {...input}
                              id="profile-version"
                              label=".metadata.profile_version (advanced)"
                              InputLabelProps={{ shrink: true }}
                              error={meta.touched && !!meta.error}
                              helperText={
                                meta.touched && meta.error
                                  ? ".metadata.profile_version is required"
                                  : ""
                              }
                            />
                          </Box>
                        )}
                      </Field>
                      <Box>
                        <FormGroup>
                          <Field
                            name="released"
                            subscription={{
                              value: true,
                              touched: true,
                              error: true,
                            }}
                          >
                            {({ input, meta }) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    {...input}
                                    checked={!!input.value}
                                  />
                                }
                                label=".metadata.flags.released"
                              />
                            )}
                          </Field>
                          <Field
                            name="external"
                            subscription={{
                              value: true,
                            }}
                          >
                            {({ input, meta }) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    {...input}
                                    checked={!!input.value}
                                  />
                                }
                                label='.metadata.annotations["sdk@is_external"]'
                              />
                            )}
                          </Field>
                          <Field
                            name="slProfile"
                            subscription={{
                              value: true,
                            }}
                          >
                            {({ input }) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    {...{
                                      ...input,
                                      onChange: (
                                        v: ChangeEvent<HTMLInputElement>
                                      ) =>
                                        input.onChange(
                                          v.target.checked ? true : undefined
                                        ),
                                    }}
                                    checked={!!input.value}
                                  />
                                }
                                label='.metadata.annotations["sl@sl_profile"]'
                              />
                            )}
                          </Field>
                          <Field
                            name="isAssignedProfile"
                            subscription={{
                              value: true,
                            }}
                          >
                            {({ input }) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    {...{
                                      ...input,
                                      onChange: (
                                        v: ChangeEvent<HTMLInputElement>
                                      ) =>
                                        input.onChange(
                                          v.target.checked ? true : undefined
                                        ),
                                    }}
                                    checked={!!input.value}
                                  />
                                }
                                label='.metadata.annotations["sl@is_assigned_profile"]'
                              />
                            )}
                          </Field>
                          <Field
                            name="isInMoment"
                            subscription={{
                              value: true,
                            }}
                          >
                            {({ input }) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    {...{
                                      ...input,
                                      onChange: (
                                        v: ChangeEvent<HTMLInputElement>
                                      ) =>
                                        input.onChange(
                                          v.target.checked ? true : undefined
                                        ),
                                    }}
                                    checked={!!input.value}
                                  />
                                }
                                label='.metadata.annotations["sl@is_in_moment"]'
                              />
                            )}
                          </Field>
                          <Field
                            name="shouldIncludeAdCampaignEstimatorMetadata"
                            subscription={{
                              value: true,
                            }}
                          >
                            {({ input, meta }) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    {...input}
                                    checked={!!input.value}
                                  />
                                }
                                label="Should include .metadata.annotations.ad_campaign_estimator?"
                              />
                            )}
                          </Field>
                        </FormGroup>
                      </Box>
                      <Box
                        sx={{
                          pt: 2,
                          pb: 2,
                        }}
                      >
                        <FormControl fullWidth>
                          <InputLabel id="cvcql_version_label">
                            .metadata.cvcql_version
                          </InputLabel>
                          <Field
                            name="cvcqlVersion"
                            labelId="cvcql_version_label"
                            validate={required}
                            subscription={{
                              value: true,
                              touched: true,
                              error: true,
                            }}
                          >
                            {({ input, meta }) => (
                              <Select
                                defaultValue={"1.2.0"}
                                {...input}
                                label=".metadata.cvcql_version"
                                sx={{ textAlign: "left" }}
                              >
                                <MenuItem value={"1.2.0"}>1.2.0</MenuItem>
                                <MenuItem value={"1.5.0"}>1.5.0</MenuItem>
                                <MenuItem value={"1.8.0"}>1.8.0</MenuItem>
                              </Select>
                            )}
                          </Field>
                        </FormControl>
                      </Box>
                      <Field
                        name="query"
                        subscription={{
                          value: true,
                          touched: true,
                          error: true,
                        }}
                      >
                        {({ input, meta }) => (
                          <Box
                            sx={{
                              pt: 2,
                              pb: 2,
                              textAlign: "left",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              {/* TODO make the error checking work here with the /segment/v1/check endpoint if possible */}
                              <FormControl sx={{ width: "100%" }}>
                                <InputLabel shrink={true} htmlFor="editor">
                                  .query (advanced)
                                </InputLabel>
                                <OutlinedInput
                                  id="editor"
                                  placeholder="Write a CVCQL query here"
                                  notched
                                  sx={{
                                    p: 1,
                                  }}
                                  label=".query (advanced)"
                                  slots={{
                                    input: Editor,
                                  }}
                                  slotProps={{
                                    input: ({
                                      value: input.value,
                                      onValueChange: input.onChange,
                                      highlight: (x: any) => x,
                                      style: {
                                        fontFamily:
                                          '"Fira code", "Fira Mono", "Roboto Mono", "Consolas", monospace',
                                        fontSize: 12,
                                        width: "100%",
                                        minHeight: "5em",
                                      },
                                    } as unknown) as HTMLAttributes<
                                      HTMLDivElement
                                    > &
                                      InputBaseComponentsPropsOverrides,
                                  }}
                                />
                              </FormControl>
                              <Button
                                variant="outlined"
                                onClick={() => {
                                  try {
                                    input.onChange(
                                      JSON.stringify(
                                        JSON.parse(input.value),
                                        null,
                                        2
                                      )
                                    );
                                  } catch (e) {
                                    SnackbarUtilsConfigurator.warning(
                                      "Unable to format due to syntax error: " +
                                        (e as any).message
                                    );
                                  }
                                }}
                              >
                                Format
                              </Button>
                            </Box>
                            <Typography
                              variant="caption"
                              sx={{
                                color: theme.palette.text.secondary,
                              }}
                            >
                              Use this box to put in a custom CVCQL query. If
                              filled, it takes precedance over the Selected
                              traits box below.{" "}
                              <b>
                                If Selected traits has been edited and this
                                field is filled out behaviour is undefined
                              </b>
                            </Typography>
                          </Box>
                        )}
                      </Field>
                    </Box>
                  ) : (
                    <></>
                  )}
                  <Field
                    name="name"
                    validate={required}
                    subscription={{ value: true, touched: true, error: true }}
                  >
                    {({ input, meta }) => (
                      <Box>
                        <CvcTextField
                          showSkeleton={!defaultsHaveBeenSet}
                          {...input}
                          id="name"
                          label="Name"
                          InputLabelProps={{ shrink: true }}
                          error={meta.touched && !!meta.error}
                          helperText={
                            meta.touched && meta.error ? "Name is required" : ""
                          }
                        />
                      </Box>
                    )}
                  </Field>
                  <Field
                    name="description"
                    validate={required}
                    subscription={{ value: true, touched: true, error: true }}
                  >
                    {({ input, meta }) => (
                      <Box>
                        <CvcTextField
                          showSkeleton={!defaultsHaveBeenSet}
                          {...input}
                          id="description"
                          label="Description"
                          InputLabelProps={{ shrink: true }}
                          multiline={true}
                          minRows={1}
                          error={meta.touched && !!meta.error}
                          helperText={
                            meta.touched && meta.error
                              ? "Description is required"
                              : ""
                          }
                        />
                      </Box>
                    )}
                  </Field>
                  <Field
                    name="query"
                    subscription={{
                      value: true,
                    }}
                  >
                    {({ input: queryInput }) => {
                      if (queryInput?.value) {
                        return (
                          <>
                            <Field name="selectedsegments">{() => <></>}</Field>
                            <CvcTextField
                              label="Selected traits"
                              showSkeleton={false}
                              disabled
                              helperText="Disabled because query was specified"
                            ></CvcTextField>
                            <CvcTextField
                              label="Selection criteria"
                              showSkeleton={false}
                              disabled
                              helperText="Disabled because query was specified"
                            ></CvcTextField>
                            <CvcTextField
                              label="Target duration"
                              showSkeleton={false}
                              disabled
                              helperText="Disabled because query was specified"
                            ></CvcTextField>
                          </>
                        );
                      }
                      return (
                        <>
                          <Field
                            name="selectedsegments"
                            // validate={!!queryInput?.value ? () => {} : isNotEmpty}
                            validate={isNotEmpty}
                            subscription={{
                              value: true,
                              touched: true,
                              error: true,
                            }}
                          >
                            {({ input, meta }) => {
                              return (
                                <Box>
                                  <SegmentPickerV1
                                    showSkeleton={!defaultsHaveBeenSet}
                                    {...input}
                                    onChange={(v) => input.onChange([...v])}
                                    error={meta.touched && !!meta.error}
                                    helperText={
                                      meta.touched && meta.error
                                        ? "Segments are required"
                                        : ""
                                    }
                                  />
                                </Box>
                              );
                            }}
                          </Field>

                          {/* ******************** selection criteria ************************* */}
                          <Field
                            name="operator"
                            validate={required}
                            subscription={{
                              value: true,
                              touched: true,
                              error: true,
                            }}
                          >
                            {({ input, meta }) => (
                              <Box
                                sx={{
                                  textAlign: "left",
                                }}
                              >
                                <FormControl
                                  fullWidth
                                  sx={{
                                    mt: 2,
                                    mb: 2,
                                  }}
                                >
                                  <InputLabel id="operator-label">
                                    Selection criteria
                                  </InputLabel>
                                  <Select
                                    labelId="operator-label"
                                    {...input}
                                    id="operator"
                                    label="Selection criteria"
                                  >
                                    {Object.entries(operatorsText).map((x) => (
                                      <MenuItem
                                        value={x[0]}
                                        sx={{
                                          whitespace: "normal",
                                          display:
                                            x[0] === input.value
                                              ? "none"
                                              : "inherit",
                                        }}
                                        autoFocus={x[0] !== input.value}
                                      >
                                        {x[1]}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Box>
                            )}
                          </Field>

                          {/* Target duration */}
                          <Field
                            name="targetduration"
                            validate={required}
                            subscription={{
                              value: true,
                              touched: true,
                              error: true,
                            }}
                          >
                            {({ input, meta }) => (
                              <Box>
                                <CvcTextField
                                  {...input}
                                  showSkeleton={!defaultsHaveBeenSet}
                                  id="target-metric-duration"
                                  label="Target duration"
                                  // type="number"
                                  onBlur={(e: FocusEvent) => {
                                    if (+input.value < 1)
                                      return input.onChange("1");
                                    if (+input.value > 12)
                                      return input.onChange("12");
                                  }}
                                  onChange={(e: ChangeEvent<any>) => {
                                    console.log(e.target.value);
                                    if (e.target.value === "")
                                      return input.onChange("");
                                    if (
                                      (e.target.value as String).match(/-[0-9]/)
                                    )
                                      return input.onChange(e.target.value);
                                    const value: number = +e.target.value;
                                    if (value < 1) return input.onChange("1");
                                    if (value > 12) return input.onChange("12");
                                    input.onChange(e.target.value);
                                  }}
                                  // inputProps={{ min: 1, max: 12 }}
                                  // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                  InputProps={
                                    {
                                      step: 1,
                                      min: 1,
                                      max: 12,
                                      type: "number",
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          weeks
                                        </InputAdornment>
                                      ),
                                    } as InputProps
                                  }
                                  inputProps={{
                                    style: { textAlign: "right" },
                                  }}
                                />
                              </Box>
                            )}
                          </Field>

                          {/* Estimation table */}
                          <Field
                            name="selectedsegments"
                            subscription={{ value: true }}
                            render={({
                              input: { value: selectedsegments },
                            }) => {
                              return (
                                <Field
                                  name="targetduration"
                                  subscription={{ value: true }}
                                  render={({
                                    input: { value: targetduration },
                                  }) => {
                                    return (
                                      <Field
                                        name="operator"
                                        subscription={{ value: true }}
                                        render={({
                                          input: { value: operator },
                                        }) => {
                                          return (
                                            <Box
                                              sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <EstimationTable
                                                showSkeleton={
                                                  !defaultsHaveBeenSet
                                                }
                                                targetduration={targetduration}
                                                selectedsegments={
                                                  selectedsegments
                                                }
                                                operator={operator}
                                              />
                                            </Box>
                                          );
                                        }}
                                      />
                                    );
                                  }}
                                />
                              );
                            }}
                          />
                        </>
                      );
                    }}
                  </Field>

                  <FormSpy
                    subscription={{
                      touched: true,
                      errors: true,
                    }}
                    onChange={(props) => {
                      console.log("props");
                      console.log(props);
                      setShouldDisableSave(
                        Object.keys(props.errors as Object)
                          .map(
                            (k) => !props?.errors?.[k] || !props?.touched?.[k]
                          )
                          .some((x) => !x)
                      );
                    }}
                  />
                </form>

                {/* ****************************** get target back **************************8 */}
                <Box
                  sx={{
                    textAlign: "left",
                    position: "sticky",
                    bottom: 0,
                    zIndex: 1000,
                    // background: "white",
                    // pr: 10,
                  }}
                >
                  {/* TODO use theme colors here intsead of white */}
                  <Box
                    sx={{
                      pt: 6,
                      backgroundImage:
                        "linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))",
                    }}
                  ></Box>
                  <Box sx={{ pt: 2, background: "white" }}></Box>
                  <Box sx={{ background: "white" }}>
                    {/* This FormSpy can likely be made much more efficient by
                  becoming it's own component. At the moment it exploits the
                  isLoading defined at the top if this function, unfortunately,
                  this causes the entire page to re-render whenever a set of
                  queries starts or finishes. This isn't the end of the world
                  since users (currently) don't have much reason to continue
                  trying to do stuff as the page loads, but for the sake of an
                  hour and some tests, it seems a waste not to do it */}
                    <FormSpy
                      // subscription={{ values: true, touched: true, errors: true}}
                      render={(form) => (
                        <>
                          <SubmitButton
                            _segments={form.values.selectedsegments}
                            _operator={form.values.operator}
                            type="submit"
                            form="segmentCreationForm"
                            disabled={isLoading || shouldDisableSave}
                            variant="contained"
                            startIcon={<Save />}
                            sx={{ width: "100%" }}
                          >
                            {isLoadingProfileMutation
                              ? !!params.metadataName
                                ? "Updating..."
                                : "Saving..."
                              : !!params.metadataName
                              ? "Update"
                              : "Save"}
                          </SubmitButton>
                        </>
                      )}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: {
                    xs: "none",
                    md: "flex",
                  },
                  flexDirection: "column",
                  color: theme.palette.text.secondary,
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.03)",
                  ":hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                    cursor: "pointer",
                  },
                  pl: 1,
                  pr: 1,
                  borderRadius: "8px",
                }}
                onClick={() => setHideChart(!hideChart)}
              >
                <>
                  {hideChart ? <ArrowBack /> : <ArrowForward />}
                  <Typography
                    variant="caption"
                    sx={{ writingMode: "vertical-lr", pt: 1, pb: 1 }}
                  >
                    {hideChart ? "Show info" : "Hide info"}
                  </Typography>
                  {hideChart ? <ArrowBack /> : <ArrowForward />}
                </>
              </Box>
              {hideChart ? (
                <Box sx={{ ml: 4 }}></Box>
              ) : (
                <Field name="selectedsegments" subscription={{ value: true }}>
                  {({ input: { value: selectedsegments } }) => {
                    return (
                      <Field
                        name="targetduration"
                        subscription={{ value: true }}
                      >
                        {({ input: { value: targetduration } }) => {
                          return (
                            <Field
                              name="operator"
                              subscription={{ value: true }}
                            >
                              {({ input: { value: operator } }) => {
                                return (
                                  <Box
                                    sx={{
                                      flex: 1,
                                      p: 1,
                                      m: 1,
                                      // This is so the left hand size flex
                                      // thing doesn't become too small
                                      width: 0,
                                      flexDirection: "column",
                                      display: { xs: "none", md: "flex" },
                                    }}
                                  >
                                    <Tabs
                                      value={tabValue}
                                      onChange={(_, newVal: number) =>
                                        setTabValue(newVal)
                                      }
                                    >
                                      <Tab label="Estimation chart" />
                                      <Tab
                                        label={
                                          <div
                                            style={{ display: "inline-block" }}
                                          >
                                            About this segment&nbsp;
                                            <Chip
                                              color="warning"
                                              label="beta"
                                              size="small"
                                            />
                                          </div>
                                        }
                                      />
                                    </Tabs>
                                    <TabPanel value={tabValue} index={0}>
                                      {/* <Box data-testid="EstimationChart"> */}
                                      {renderLineChart(
                                        selectedsegments,
                                        targetduration,
                                        operator
                                      )}
                                      {/* </Box> */}
                                    </TabPanel>
                                    <TabPanel value={tabValue} index={1}>
                                      {/* TODO make this not be rendered twice because it's in 3 fields and a formspy */}
                                      <FormSpy
                                        render={(form) => {
                                          const values = form.values as FormDefaults;
                                          return (
                                            <AboutThisSegment
                                              segmentName={values.name}
                                              segments={values.selectedsegments}
                                              operator={values.operator}
                                              estimatedWeeks={+values.targetduration || 0}
                                            />
                                          );
                                        }}
                                      ></FormSpy>
                                    </TabPanel>
                                  </Box>
                                );
                              }}
                            </Field>
                          );
                        }}
                      </Field>
                    );
                  }}
                </Field>
              )}
            </>
          )}
        ></Form>
      </Box>
    </Box>
  );
};

export default MyForm;
