import { createSlice } from "@reduxjs/toolkit";
import { isAfter, sub } from "date-fns";




export interface userStatus {
  email: string;
  idToken: string | null;
  preventLogoutRedirect: boolean,
}

const initialState: userStatus = {
  email: "unknown",
  // setIdToken is where all the expiry checking is done, so this should be
  // null when the app starts
  idToken: null,
  preventLogoutRedirect: false
};



// taken from: https://stackoverflow.com/a/38552302/2060855
function parseJwt(token: string): {email: string, exp: number} | undefined {
  var base64Url = token.split('.')[1]; 
  if (base64Url === undefined) {
    return
  }
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export function isIdTokenExpired(idToken: string | null): boolean {
  if (idToken === null) {
    return true
  }
  const claims = parseJwt(idToken) 
  if (claims?.exp === undefined) {
    return true
  }
  
  const claimsDate = new Date(claims.exp * 1000)
  const now = new Date()
  const retval = isAfter(now, claimsDate)

  return retval
}

export function howLongAgoDidTheIdTokenExpire(idToken: string | null): number | undefined {
  if (idToken === null) {
    return 
  }
  const claims = parseJwt(idToken) 
  if (claims?.exp === undefined) {
    return 
  }
  
  const claimsDate = new Date(claims.exp * 1000)
  const now = new Date()
  const differenceInSeconds = (+now - +claimsDate) / 1000

  return differenceInSeconds
}


export const userStatusSlice = createSlice({
  name: "userStatus",
  initialState,
  reducers: {
    setIdToken: (state, action) => { 
      // Shh, I know I shouldn't do localStorage in here, but it's the logical place
      state.idToken = action.payload; 

      if (action.payload === null || isIdTokenExpired(state.idToken)) {
        localStorage.removeItem('idToken')
        state.preventLogoutRedirect = true
        return
      }
      
      state.email = parseJwt(action.payload)?.email ?? "unknown"

      localStorage.setItem('idToken', action.payload)
    },
  },
});


export const { setIdToken } = userStatusSlice.actions;

export default userStatusSlice.reducer;
