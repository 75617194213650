import { Alert, Box, Button, Skeleton, Typography } from "@mui/material";
import React from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import { Segment, useGetReachAndAvailsQuery } from "../../services/api";

const EstimationChart = ({
  selectedsegments,
  availsRef,
  reachRef,
  durationRef,
  showSkeleton,
  operator,
}: {
  // data: any[];
  selectedsegments: Segment[];
  availsRef?: number;
  reachRef?: number;
  durationRef?: number;
  showSkeleton: boolean;
  operator: "AND" | "OR";
}) => {
  const { data, error, isFetching, refetch } = useGetReachAndAvailsQuery({
    profiles: selectedsegments?.map((x: Segment) => x.segment) ?? [],
    operator,
  });

  const maxAvails =
    data?.reduce((prev: number, curr: { avails: number }) => {
      return prev > curr.avails ? prev : +curr.avails;
    }, 0) ?? 0;
  const maxReach =
    data?.reduce((prev: number, curr: { reach: number }) => {
      return prev > curr.reach ? prev : +curr.reach;
    }, 0) ?? 0;
  // const minReach =
  //   data?.reduce((prev: number, curr: { reach: number }) => {
  //     return prev > curr.reach ? prev : +curr.reach;
  //   }, 0) ?? 0;

  const availsColor = "#8884d8";
  const reachColor = "#82ca9d";

  if (!!error) {
    return (
      <Alert
        severity="error"
        action={
          <Button color="error" onClick={refetch}>
            retry
          </Button>
        }
      >
        Hmm, something went wrong on our end &ndash; If this problem persists,
        please contact Covatic directly
      </Alert>
    );
  }

  if (showSkeleton || (isFetching && (!data || data.length === 0))) {
    return (
      <Box>
        <Skeleton width="100%" />
        <Skeleton width="61%" />
      </Box>
    );
  }

  if (selectedsegments.length === 0 || !data || data.length === 0) {
    return (
      <Box
        sx={{
          pt: 3,
          pb: 3,
          pl: 1,
          pr: 1,
        }}
      >
        <Alert severity="info">
          Type in segments on the left to see an estimate
        </Alert>
      </Box>
    );
  }

  return (
    // <LineChart width={600} height={300} data={data} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
    //  <ResponsiveContainer width="100%" height="100%">
    <Box component="span" sx={{ opacity: isFetching ? 0.3 : 1 }}>
      <ResponsiveContainer>
        <LineChart
          data={data?.map((x: any) => ({
            name: "Week " + x["num of weeks"],
            ...x,
          }))}
          margin={{ top: 5, right: 50, bottom: 50, left: 50 }}
        >
          <XAxis
            dataKey="num of weeks"
            label={{ value: "Number of weeks", position: "bottom" }}
          />
          <YAxis
            tickFormatter={(v) => v.toLocaleString()}
            yAxisId="left"
            label={{
              value: "Inventory",
              angle: -90,
              position: "left",
              offset: 40,
            }}
            //   padding={{ bottom: 100 }}
            domain={[0, maxAvails > (availsRef ?? 0) ? maxAvails : availsRef]}
          />
          <YAxis
            tickFormatter={(v) => v.toLocaleString()}
            yAxisId="right"
            orientation="right"
            label={{ value: "Reach", angle: 90, position: "right", offset: 40 }}
            domain={[
              0,
              maxReach > (reachRef ?? 0)
                ? maxReach * 1.3
                : (reachRef ?? 0) * 1.3,
            ]}
          />

          <Line
            yAxisId="left"
            type="monotone"
            dataKey="avails"
            name="Inventory"
            isAnimationActive={false}
            stroke={availsColor}
          />
          <Line
            yAxisId="right"
            type="monotone"
            dataKey="reach"
            name="Reach"
            isAnimationActive={false}
            stroke={reachColor}
          />
          {/* <Line type="monotone" dataKey="avails" stroke="#8884d8" />
          <Line type="monotone" dataKey="reach" stroke="#848808" /> */}
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <Tooltip formatter={(v) => v.toLocaleString()} />
          <Legend
            verticalAlign="top"
            margin={{ top: 80, bottom: 80, right: 0, left: 0 }}
          />
          {(availsRef ?? 0) > 0 ? (
            <ReferenceLine
              yAxisId="left"
              y={availsRef ?? 0}
              label="target inventory"
              stroke={availsColor}
              strokeDasharray="10 10 "
            />
          ) : (
            <></>
          )}
          {(reachRef ?? 0) > 0 ? (
            <ReferenceLine
              yAxisId="right"
              y={reachRef ?? 0}
              label="target reach"
              stroke={reachColor}
              strokeDasharray="12 12 "
            />
          ) : (
            <></>
          )}
          {(durationRef ?? 0) > 0 ? (
            <ReferenceLine
              yAxisId="left"
              x={durationRef ?? 0}
              // label="target duration"
              label={{
                value: "Target duration",
                angle: -90,
                position: "right",
                offset: 10,
              }}
              stroke="green"
            />
          ) : (
            <> </>
          )}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default EstimationChart;
