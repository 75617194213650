import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
// import IconButton from '@mui/material/IconButton';
import Typography from "@mui/material/Typography";
// import Menu from '@mui/material/Menu';
// import MenuIcon from '@mui/icons-material/Menu';
import Container from "@mui/material/Container";
// import Avatar from '@mui/material/Avatar';
// import Button from '@mui/material/Button';
// import Tooltip from '@mui/material/Tooltip';
// import MenuItem from '@mui/material/MenuItem';
import AdbIcon from "@mui/icons-material/Adb";
import { Avatar, Button, SvgIcon } from "@mui/material";

import { ReactComponent as CovaticLogomark } from "./covatic_logo_mark_white.svg";
import { ReactComponent as CovaticLogo } from "./covatic_primary_logo_lockup_white.svg";
import { useAppSelector } from "../store/hooks";
import { deepOrange } from "@mui/material/colors";
import { Mail } from "@mui/icons-material";
import { Link } from "react-router-dom";

// const pages = ['Products', 'Pricing', 'Blog'];
// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const companyName = "Covatic";

const ResponsiveAppBar = ({children}: {children?: React.ReactNode}) => {
  // const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  // console.log(anchorElNav)
  // const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  // const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorElNav(event.currentTarget);
  // };
  // const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorElUser(event.currentTarget);
  // };

  // const handleCloseNavMenu = () => {
  //   setAnchorElNav(null);
  // };

  // const handleCloseUserMenu = () => {
  //   setAnchorElUser(null);
  // };

  const email = useAppSelector(state => state.userStatus.email)
  return (
    <AppBar position="static">
      <Toolbar disableGutters>
      <Link to="/" style={{ marginTop: "-2px" }}>
        <CovaticLogo width={130 + 2 * 14}/>
      </Link>
      <Link to="/" style={{textDecoration: 'none', color: 'unset'}}>
        <Typography
          variant="h6"
          sx={{
            ml: 2
          }}
        >
          Audience Builder
        </Typography>
      </Link>

        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
          {/* <IconButton
              size="large"
              aria-label="account of current user"
                     {/* <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>    aria-controls="menu-appbar"
              aria-haspopup="true"
              // onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton> */}
          {/* <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu> */}
        </Box>
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          {/* {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page}
              </Button>
            ))} */}
        </Box>

        <Box sx={{ flexGrow: 0, pr: 2 }}>
          {children}
          {/* <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu> */}
        </Box>
        <Button variant="outlined"
        color="secondary"
        component="a"
        href="mailto:dan@covatic.com"
        startIcon={<Mail />}
        sx={{ color: 'white', borderColor: 'white' }}>Contact us</Button>
        <Box sx={{pl: 2, pr: 2}}>
          {email.length ? 
            <Avatar sx={{bgcolor: deepOrange[500]}}>{email[0].toUpperCase()}</Avatar>
            : <></>
          }
          </Box>
      </Toolbar>
    </AppBar>
  );
};
export default ResponsiveAppBar;
