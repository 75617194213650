import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { EmojiNature, EmojiNatureTwoTone } from "@mui/icons-material";
import { Link } from "react-router-dom";
import ResponsiveAppBar from "../components/ResponsiveAppBar";

const ErrorPage = () => {
  return (
    <>
      <ResponsiveAppBar></ResponsiveAppBar>
      <Box
        sx={{
          flexGrow: 1,
          p: 12,
        }}
      >
        <EmojiNatureTwoTone
          sx={{
            fontSize: "20rem",
          }}
        />
        <Typography variant="h1" sx={{ pb: 4 }}>
          Oops, page not found
        </Typography>
        <Typography variant="h3">
          <Link to="/">Click here</Link> to view your estimates &amp; live
          segments
        </Typography>
        {/* <Typography variant="h3"><Link to="/">Click here</Link> to go back</Typography> */}
      </Box>
    </>
  );
};

export default ErrorPage;
