import * as React from "react";
import { useMemo, useState, ChangeEvent, SyntheticEvent } from "react";
import Autocomplete from "@mui/material/Autocomplete";

import { Box, Chip, FilterOptionsState, Typography } from "@mui/material";
import { store } from "../store";
import {
  Segment,
  SegmentType,
  useGetProfilesQuery,
  useGetSegmentsQuery,
} from "../services/api";
import CvcTextField from "./CvcTextField";
import { selectSegmentDisplayNames } from "../store/selectors";
import { formatSegmentName } from "../services/formatters";
import { matchSorter } from "match-sorter";

// This is a 'section mark' which is extremely rare in modern text and even
// less likely to be added as a segment name. This is kind of a hack to make
// sure that numeric profiles get sorted to the bottom, but it's the most
// stable and safe way I can figure out on short notice!
const LAST_IN_SORT_PREFIX = "§§§";

// const formattedSegmentNames = segmentNames.map((x) => ({ title: x, value: x }));
// TODO use RTK Query with the store to make this be loaded from Julia's script
//

type onChangeFunction = (value: Segment[]) => void;

// This is used to control the number of suggestions shown underneath the primary input
const suggestionsLength = 3;

export default function SegmentPickerV1({
  value,
  onChange,
  error,
  helperText,
  onBlur,
  onFocus,
  showSkeleton,
}: {
  value: Segment[];
  onChange: onChangeFunction;
  error: boolean | undefined;
  helperText: string;
  onBlur: any;
  onFocus: any;
  showSkeleton: boolean;
}) {
  // // TODO handle an error gracefully
  /////////////////////

  // const selectedSegments: any = useSelector((state: RootState) => state.selectedSegments)
  // const [selectedSegments, setSegments] = useState([] as string[])
  const {
    data: allSegments,
    isFetching: allSegmentsFetching,
    error: allSegmentsError,
  } = useGetSegmentsQuery();

  const { data: allCovaticSegments } = useGetProfilesQuery();

  const [suggestions, setSuggestions] = useState<Segment[]>([]);

  const segmentDisplayNameMap = useMemo<{ [k: string]: string }>(() => {
    // console.log("Getting the display names");
    // console.log(allSegments);
    // console.log(allCovaticSegments);
    return selectSegmentDisplayNames(store.getState());
  }, [allSegments, allCovaticSegments]);

  const optionsWithSearchValueEnriched = useMemo<Segment[]>(
    () =>
      allSegments
        ?.filter(
          (s: Segment) => !(s.source === SegmentType.UNKNOWN)
        )
        ?.map((s: Segment) => ({
          ...s,
          // If the display name is the same as the search value
          searchValue:
            segmentDisplayNameMap[s.segment] === s.searchValue
              ? LAST_IN_SORT_PREFIX + " " + s.searchValue
              : segmentDisplayNameMap[s.segment] + " " + s.searchValue,
        })) ?? [],
    [allSegments, segmentDisplayNameMap]
  );

  // const options = useMemo(() => allSegments?.map(x => ({title: x.segment, value: x.segment})) ?? [], [allSegments])
  // const options = useMemo(() => allSegments?.map(x => ({title: x.segment, value: x.segment})) ?? [], [allSegments])

  /////////////////

  if (!!allSegmentsError) {
    return (
      <div>
        Sorry, something went wrong. Please reload the page. Tell Covatic:{" "}
        {JSON.stringify(allSegmentsError)}
      </div>
    );
  }

  const handleChange = (_event: unknown, value: Segment[]) => {
    setSuggestions(suggestions.filter((s) => s !== value?.[value.length - 1]));
    onChange(value);
  };

  const arraysAreEqual = (array1: any[], array2: any[]): boolean =>
    array1.length == array2.length &&
    array1.every(function(element, index) {
      return element === array2[index];
    });

  const filterOptionsFunc = (
    options: Segment[],
    state: FilterOptionsState<Segment>
  ): Segment[] => {
    // Wierdly, this is more performant than the default, and more intuitive!
    const matches = matchSorter(options, state.inputValue, {
      keys: ["searchValue"],
      baseSort: (a, b) => {
        if (
          a.rankedValue.startsWith(LAST_IN_SORT_PREFIX) &&
          b.rankedValue.startsWith(LAST_IN_SORT_PREFIX)
        ) {
          return String(a.rankedValue).localeCompare(b.rankedValue);
        }
        if (a.rankedValue.startsWith(LAST_IN_SORT_PREFIX)) return 1;
        if (b.rankedValue.startsWith(LAST_IN_SORT_PREFIX)) return -1;
        return String(a.rankedValue).localeCompare(b.rankedValue);
      },
    });

    // Side effect: populate the suggestions based on what is matched here only
    // if the matched list has changed
    const possibleSuggestions = matches.slice(0, 50);
    if (
      state.inputValue !== "" &&
      !arraysAreEqual(suggestions, possibleSuggestions)
    ) {
      setSuggestions(possibleSuggestions);
    }

    return matches;
  };

  return (
    <Box>
      <Box>
        <Autocomplete
          value={value}
          onChange={handleChange}
          multiple
          getOptionLabel={(segment) =>
            formatSegmentName(segmentDisplayNameMap, segment.segment)
          }
          filterSelectedOptions
          filterOptions={filterOptionsFunc}
          isOptionEqualToValue={(x, y) => x.segment === y.segment}
          options={optionsWithSearchValueEnriched}
          // options={formattedSegmentNames.map(x => x)}
          popupIcon={false}
          autoHighlight
          renderTags={(value: Segment[], getTagProps) => (
            <div style={{ width: "100%", textAlign: "left" }}>
              {value.map((option: Segment, index: number) => {
                const name = formatSegmentName(
                  segmentDisplayNameMap,
                  option.segment
                );
                return (
                  // We use data-test-estimation-api-id to verify that the
                  // right segments are in the selected segments picker in the
                  // edit tests
                  <Chip
                    data-test-estimation-api-id={option.segment}
                    title={name}
                    label={name}
                    {...getTagProps({ index })}
                  />
                );
              })}
            </div>
          )}
          renderInput={(params) => (
            <CvcTextField
              {...params}
              showSkeleton={showSkeleton}
              error={error}
              onBlur={onBlur}
              onFocus={onFocus}
              helperText={helperText}
              InputLabelProps={{ shrink: true }}
              multiline
              label="Selected traits"
              placeholder="Type to select segments..."
              rows={3}
            />
          )}
        />
      </Box>
      {!error && suggestions.length > 0 && (
        <Box
          sx={{
            textAlign: "left",
            mt: -2,
            mb: 2,
          }}
        >
          <Typography variant="caption">
            Suggested segments:{" "}
            {suggestions.slice(0, suggestionsLength).map((segment, i) => (
              <Box
                component="span"
                sx={{
                  ":hover": {
                    textDecoration: "underline",
                    cursor: "pointer",
                  },
                }}
                key={"segment-suggestion-" + i}
                onClick={() => {
                  onChange([...value, segment]);
                  setSuggestions(suggestions.filter((s) => s !== segment));
                }}
              >
                {formatSegmentName(segmentDisplayNameMap, segment.segment) +
                  (i < suggestionsLength - 1 ? ", " : "")}
              </Box>
            ))}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
