import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//   segments: [] as string[],
// };

const initialState = [] as string[];

export const selectedSegmentsSlice: any = createSlice({
  name: "selectedSegments",
  initialState,
  reducers: {
    setSegments: (_, { payload }: { payload: string[] }) => {
      return [...payload];
    },
  },
});


export const { setSegments } = selectedSegmentsSlice.actions;

export default selectedSegmentsSlice.reducer;
