import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { RefObject, useEffect } from "react";
import { Field, FormSpy } from "react-final-form";
import { ls } from "./ls";

interface CheckboxGroupWithOtherProps {
  title: string;
  prompts: { [title: string]: string };
  placeholder: string;

  primaryField: string;
  otherField: string;

  inputRef: RefObject<HTMLInputElement>;
}

const addToInputArray = (
  arr: string[],
  value: string,
  keepList: string[]
): string[] => {
  let retval = [
    ...new Set([...arr.filter((x) => keepList.includes(x)), value]),
  ];
  // let retval = [...arr.filter((x) => keepList.includes(x)), value]
  retval.sort();
  return retval;
};

const removeFromInputArray = (arr: string[], value: string): string[] => {
  return arr.filter((x) => x !== value);
};

const CheckboxGroupWithOther = (props: CheckboxGroupWithOtherProps) => {
  return (
    <Field name={props.primaryField}>
      {({ input, meta }) => {
        const values = input.value as string[];

        return (
          <Field
            name={props.otherField}
            validate={(x) =>
              typeof x === "string" && x.length > 0
                ? undefined
                : "invalid input"
            }
          >
            {({ input: otherInput, meta: otherMeta }) => {
              // This s a set of functions used to manage state and values
              const manageCheckboxValue = (
                arr: string[],
                value: string,
                checkboxValue: boolean
              ): string[] =>
                checkboxValue
                  ? addToInputArray(arr, value, [
                      ...Object.keys(props.prompts),
                      // This means that the value currently showing in 'other' is always part of the 'keep' list
                      ...(!Object.keys(props.prompts).includes(value)
                        ? [value]
                        : [otherInput.value]),
                    ])
                  : removeFromInputArray(arr, value);
              const inputOnChange = (
                existingValues: string[],
                newValue: string,
                checkboxValue: boolean
              ) => {
                const valuesToSet = manageCheckboxValue(
                  existingValues,
                  newValue,
                  checkboxValue
                );
                input.onChange(valuesToSet);
                ls.setArray<string[]>(props.primaryField, valuesToSet);
              };

              // This boolean is complicated so it deserves explaining
              const isInvalid: boolean =
                // If there is an error (cast to a boolean)
                !!otherMeta.error &&
                // and the current selected value includes
                values.includes("") &&
                // and the text field is *not* currently focused
                !(document.activeElement === props.inputRef.current);

              return (
                <FormControl>
                  <FormLabel>{props.title}:</FormLabel>
                  <FormGroup row>
                    {Object.keys(props.prompts).map((prompt) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.includes(prompt)}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              inputOnChange(values, prompt, e.target.checked)
                            }
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label={prompt}
                      />
                    ))}
                    <>
                      <FormControlLabel
                        value={otherInput.value}
                        onFocus={() => props.inputRef.current?.focus()}
                        control={
                          <Checkbox
                            checked={values.includes(otherInput.value)}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              inputOnChange(
                                values,
                                e.target.value,
                                e.target.checked
                              );
                              if (e.target.checked) {
                                props.inputRef.current?.focus();
                              } else {
                                props.inputRef.current?.blur();
                              }
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label={
                          <TextField
                            placeholder={props.placeholder}
                            onChange={(e) => {
                              otherInput.onChange(e.target.value);
                              ls.set<string>(props.otherField, e.target.value);
                              inputOnChange(values, e.target.value, true);
                            }}
                            onFocus={otherInput.onFocus}
                            onBlur={otherInput.onBlur}
                            onClick={() => {
                              inputOnChange(values, otherInput.value, true);
                            }}
                            inputProps={{
                              ref: props.inputRef,
                            }}
                            value={otherInput.value}
                            error={isInvalid}
                            helperText={
                              isInvalid
                                ? "This field cannot be selected and empty"
                                : ""
                            }
                            label="other"
                          />
                        }
                      />
                    </>
                  </FormGroup>
                </FormControl>
              );
            }}
          </Field>
        );
      }}
    </Field>
  );
};

export default CheckboxGroupWithOther;
