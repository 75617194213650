import { formatRFC3339 } from "date-fns";
import { v4 as uuidv4 } from "uuid";

export interface ProfileAdCampaignEstimatorMetadata {
  sync_id: string;
  created_by: string;
  traits_included: string[];
  estimated_duration: number;
  estimated_avails: number;
  estimated_reach: number;
}

export default interface Profile {
  _id?: string; // This should only be present in got profiles, not in profiles we create
  metadata: {
    name: string;
    profile_version: number;
    cvcql_version: "1.2.0" | "1.5.0" | "1.8.0";
    expires: string;
    update_every: number;
    tags: string[];
    annotations: {
      ad_campaign_estimator?: ProfileAdCampaignEstimatorMetadata;
      "insights/display-name"?: string;
      "insights/description"?: string;
      "insights/last-updated"?: string;
      "sdk@is_external": boolean;
      "sl@sl_profile"?: boolean;
      "sl@is_in_moment"?: boolean;
      "sl@is_assigned_profile"?: boolean;
    };
    flags: {
      released: boolean;
      deleted: boolean;
    };
  };
  query: {
    and?: Array<Object>;
    or?: Array<Object>;
  };
}

// TODO make a createProfile function that fills out all the usual stuff like expires, profile version, flags etc.
export function createEmptyProfile(): Profile {
  return {
    metadata: {
      name: "",
      profile_version: 1,
      cvcql_version: "1.2.0",
      update_every: 7,
      tags: [],
      expires: "2100-01-01T01:01:01+00:00",
      flags: {
        released: false,
        deleted: false,
      },
      annotations: {
        "sdk@is_external": true,
        // TODO hook up the actual name!
        "insights/display-name": "",
        "insights/last-updated": formatRFC3339(new Date()),
        ad_campaign_estimator: {
          created_by: "Unknown",
          traits_included: [],
          estimated_duration: 0,
          estimated_avails: 0,
          estimated_reach: 0,
          sync_id: uuidv4(),
        },
      },
    },
    query: {},
  };
}
