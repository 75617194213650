import { formatRFC3339 } from "date-fns";
import { v4 as uuidv4 } from "uuid";

import {
  AcornTrait,
  getAcornCodesFromAcornTraitsWithAnd,
  getAcornCodesFromAcornTraitsWithOr,
  isAcornTrait,
} from "../../services/acornCodeGetter";
import { SegmentType } from "../../services/api";
import Profile, {
  createEmptyProfile,
  ProfileAdCampaignEstimatorMetadata,
} from "../../services/profile";
import { FormDefaults } from "./constants";
import acorn_groups_to_types from "./acorn_groups_to_types.json";
import mosaic_usa_groups_to_types from "./mosaic_usa_groups_to_types.json";
import acorn_categories_to_types from "./acorn_categories_to_types.json";
import { MosaicUSAVariable, getMosaicUSATypesFromMosaicUSAVariablesWithAnd, getMosaicUSATypesFromMosaicUSAVariablesWithOr, isMosaicUSAVariable } from "../../services/mosaicUSAGetter";

export const createCVCQLProfile = (
  values: FormDefaults,
  adCampaignEstimatorMetadata?: ProfileAdCampaignEstimatorMetadata
): Profile => {
  const selectedSegments = values.selectedsegments;

  // console.log(values.query);
  // try {
  //   console.log(JSON.parse(values.query ?? ""));
  // } catch (e) {
  //   console.log(e);
  // }

  // If there are any profiles we don't know the source of, we
  // can't continue with constructing the CVCQL query since we
  // don't know how to select it
  const unknownSegments = selectedSegments.filter(
    (s) => s.source === SegmentType.UNKNOWN
  );
  if (unknownSegments.length > 0) {
    throw new Error(
      "profile could not be made due to unknown segments: " +
        JSON.stringify(unknownSegments)
    );
  }
  const livesInIncluded: string[] = selectedSegments
    .filter((s) => s.source === SegmentType.LIVES_IN)
    .map((s) => s.segment.slice(3));

  const genderIncluded: string[] = selectedSegments
    .filter((s) => s.source === SegmentType.GENDER)
    .map((s) => s.segment.slice(3));

  const ageIncluded: string[] = selectedSegments
    .filter((s) => s.source === SegmentType.AGE)
    .map((s) => s.segment.slice(3));

  const acornTraitsIncluded: AcornTrait[] = selectedSegments
    .filter((s) => s.source === SegmentType.PROSPECTIVE_ACORN)
    .map((s) => s.segment.slice(3))
    .filter(isAcornTrait);

  // If the API returns something unexpected then this *could* crash, but I
  // think it's a safe bet. Maybe I should be more careful, but that would
  // involve a lot of exception handling ... which might be worth it now I
  // think about it ...
  //
  // TODO think about it a bit more
  const mosaicUSATypesIncluded: string[] = selectedSegments
    .filter((s) => s.source === SegmentType.MOSAIC_USA_TYPE)
    .map((s) => s.segment.slice(-3));

  const mosaicUSATypesIncludedFromGroups: string[][] = selectedSegments
    .filter((s) => s.source === SegmentType.MOSAIC_USA_GROUP)
    .map((s) => s.segment.slice(-1))
    .map((v) => mosaic_usa_groups_to_types[v as keyof typeof mosaic_usa_groups_to_types]);

  const mosaicUSAVariablesIncluded: MosaicUSAVariable[] = selectedSegments
    .filter((s) => s.source === SegmentType.MOSAIC_USA_VARIABLE)
    .map((s) => s.segment.slice(3))
    .filter(isMosaicUSAVariable);

  const acornTypesIncluded: Number[] = selectedSegments
    .filter((s) => s.source === SegmentType.ACORN_TYPE)
    .map((s) => +s.segment.slice(14));

  const acornTypesIncludedFromGroups: Number[][] = selectedSegments
    .filter((s) => s.source === SegmentType.ACORN_GROUP)
    .map((s) => s.segment.slice(15))
    .map((v) => acorn_groups_to_types[v as keyof typeof acorn_groups_to_types]);

  const acornTypesIncludedFromCategories: Number[][] = selectedSegments
    .filter((s) => s.source === SegmentType.ACORN_CATEGORY)
    .map((s) => s.segment.slice(18))
    .map((v) => acorn_categories_to_types[v as keyof typeof acorn_categories_to_types]);

  const covaticProfilesIncluded = selectedSegments
    .filter((s) => s.source === SegmentType.RELEASED)
    .map((s) => s.segment.slice(3));

  const defaultProfileName =
    "99" + String(Math.floor(Math.random() * 10000)).padStart(4, "0");
  const profile = createEmptyProfile();
  profile.metadata.profile_version = +values.profileVersion;
  profile.metadata.name = values.metadataName ?? defaultProfileName;

  // AND/OR
  if (values.query && values.query !== "") {
    try {
      profile.query = JSON.parse(values.query);
    } catch (e) {
      profile.query = { and: ["invalid query?"] };
    }
  } else if (values.operator === "OR") {
    const selectedAcornCodes = getAcornCodesFromAcornTraitsWithOr(
      acornTraitsIncluded
    );
    const selectedMosaicUSATypes = getMosaicUSATypesFromMosaicUSAVariablesWithOr(
      mosaicUSAVariablesIncluded
    )
    profile.query = {
      or: [
        ...acornTypesIncludedFromGroups.map((types) => ({
          or: types.map((t) => ({ acorn_code: { code: t } })),
        })),
        ...acornTypesIncludedFromCategories.map((types) => ({
          or: types.map((t) => ({ acorn_code: { code: t } })),
        })),
        ...[...selectedAcornCodes, ...acornTypesIncluded].map((x) => ({
          acorn_code: { code: x },
        })),
        ...[...mosaicUSATypesIncluded, ...selectedMosaicUSATypes].map((x) => ({
          sec: { code: x, dataset: "mosaic_usa" },
        })),
        ...mosaicUSATypesIncludedFromGroups.map((types) => ({
          or: types.map((t) => ({ sec: { code: t, dataset: "mosaic_usa" } })),
        })),
        ...livesInIncluded.map((x) => ({
          lives_in: { equals: x },
        })),
        ...genderIncluded.map((x) => ({
          gender: { equals: x },
        })),
        ...ageIncluded.map((x) => ({
          age: { range: x },
        })),
        ...covaticProfilesIncluded.map((x) => ({
          belongs_to: { target: "profiles", name: x },
        })),
      ],
    };
  } else if (values.operator === "AND") {
    const selectedAcornCodes = getAcornCodesFromAcornTraitsWithAnd(
      acornTraitsIncluded
    );
    const selectedMosaicUSATypes = getMosaicUSATypesFromMosaicUSAVariablesWithAnd(
      mosaicUSAVariablesIncluded
    )
    profile.query = {
      and: [
        ...(selectedMosaicUSATypes.length > 0
          ? [
              {
                or: selectedMosaicUSATypes.map((t) => ({
                  sec: { code: t, dataset: "mosaic_usa" },
                })),
              },
            ]
          : []),
        ...(selectedAcornCodes.length > 0
          ? [
              {
                or: selectedAcornCodes.map((t) => ({
                  acorn_code: { code: t },
                })),
              },
            ]
          : []),
        ...acornTypesIncludedFromGroups.map((types) => ({
          or: types.map((t) => ({ acorn_code: { code: t } })),
        })),
        ...acornTypesIncludedFromCategories.map((types) => ({
          or: types.map((t) => ({ acorn_code: { code: t } })),
        })),
        ...acornTypesIncluded.map((x) => ({
          acorn_code: { code: x },
        })),
        ...mosaicUSATypesIncluded.map((x) => ({
          sec: { code: x, dataset: "mosaic_usa" },
        })),
        ...mosaicUSATypesIncludedFromGroups.map((types) => ({
          or: types.map((t) => ({ sec: { code: t, dataset: "mosaic_usa" } })),
        })),
          //  Not sure that we need "and" for lives_in, won't that return zero reach?
        ...livesInIncluded.map((x) => ({
          lives_in: { equals: x },
        })),
        ...genderIncluded.map((x) => ({
          gender: { equals: x },
        })),
        ...ageIncluded.map((x) => ({
          age: { range: x },
        })),
        ...covaticProfilesIncluded.map((x) => ({
          belongs_to: { target: "profiles", name: x },
        })),
      ],
    };
  } else {
    throw "Invalid operator specified";
  }
  profile.metadata.flags.released = values.released;
  profile.metadata.flags.deleted = values.deleted;
  profile.metadata.annotations["insights/display-name"] = values.name;
  profile.metadata.annotations["insights/description"] = values.description;
  profile.metadata.annotations["insights/last-updated"] = formatRFC3339(
    new Date()
  );

  // This is possibly undefined, which means it shouldn't be filled
  profile.metadata.annotations.ad_campaign_estimator = adCampaignEstimatorMetadata;
  profile.metadata.annotations["sl@is_assigned_profile"] = values.isAssignedProfile;
  profile.metadata.annotations["sl@is_in_moment"] = values.isInMoment;
  profile.metadata.annotations["sl@sl_profile"] = values.slProfile;

  profile.metadata.annotations["sdk@is_external"] =
    values.external ?? profile.metadata.annotations["sdk@is_external"];

  // We need to do some version calculation here
  //
  // TODO this is not the neatest way to do this, really the form should figure
  // this out before it gets to this function since it's included in the form
  // values. Future values should probably not allow CVCQL version to be set
  // and instead calculate it automatically in this function
  profile.metadata.cvcql_version =
    values.cvcqlVersion ?? profile.metadata.cvcql_version;
  const minorVersion = Number(profile.metadata.cvcql_version.slice(2, 3));
  // gender is technically CVCQL version 1.3.0 but the API only supports 1.2.0, 1.5.0 and 1.6.0 and 1.8.0
  if (
    (genderIncluded.length > 0 || ageIncluded.length > 0) &&
    minorVersion < 5
  ) {
    profile.metadata.cvcql_version = "1.5.0";
  }

  if (mosaicUSATypesIncluded.length > 0 || mosaicUSAVariablesIncluded.length > 0 || mosaicUSATypesIncludedFromGroups.length > 0) {
    profile.metadata.cvcql_version = "1.8.0";
  }

  //Commenting this out, should we implement "1.9.0" as an accepted type here?
  // if (livesInIncluded.length > 0) {
  //   profile.metadata.cvcql_version = "1.9.0";
  // }

  return profile;
};

export const createAdCampaignEstimatorMetadata = (
  values: FormDefaults,
  data: { reach: number; avails: number }[],
  currentUser: string
): ProfileAdCampaignEstimatorMetadata => ({
  created_by: currentUser,
  traits_included: values.selectedsegments.map((s) => s.segment),
  estimated_duration: +values.targetduration,
  estimated_avails: data[+values.targetduration - 1]?.["avails"] ?? -1,
  estimated_reach: data[+values.targetduration - 1]?.["reach"] ?? -1,
  sync_id: uuidv4() ?? "notset",
});
